// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* General styling for the expenses content */
.expenses-content {
  --ion-background-color: #f4f4f4;
  padding: 15px; /* Add padding to the content for spacing */
}

/* Card styling */
.expenses-card {
  background-color: #2c3e50;
  color: #ecf0f1;
  padding: 20px;
  border-radius: 25px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Slight shadow for a modern look */
}

.expenses-title {
  font-size: 1.5rem;
  color: #ecf0f1;
  text-align: center;
}

/* Center card on larger screens */
.centered-card {
  max-width: 600px;
  margin: 0 auto;
}

.ion-label {
  color: #ecf0f1;
  font-weight: bold; /* Optional: make label text bold for better visibility */
}

.ion-input {
  color: white;
}

/* Flexbox styling for expenses list, side-by-side cards for desktop */
.expenses-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

/* Stack cards on smaller devices (iPad and smaller) */
@media (max-width: 1024px) {
  .expenses-grid {
    flex-direction: column;
  }

  .expenses-card {
    margin-bottom: 20px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Pages/ExpenseTracker/Expenses.css"],"names":[],"mappings":"AAAA,6CAA6C;AAC7C;EACE,+BAA+B;EAC/B,aAAa,EAAE,2CAA2C;AAC5D;;AAEA,iBAAiB;AACjB;EACE,yBAAyB;EACzB,cAAc;EACd,aAAa;EACb,mBAAmB;EACnB,0CAA0C,EAAE,oCAAoC;AAClF;;AAEA;EACE,iBAAiB;EACjB,cAAc;EACd,kBAAkB;AACpB;;AAEA,kCAAkC;AAClC;EACE,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,iBAAiB,EAAE,yDAAyD;AAC9E;;AAEA;EACE,YAAY;AACd;;AAEA,sEAAsE;AACtE;EACE,aAAa;EACb,eAAe;EACf,8BAA8B;AAChC;;AAEA,sDAAsD;AACtD;EACE;IACE,sBAAsB;EACxB;;EAEA;IACE,mBAAmB;EACrB;AACF","sourcesContent":["/* General styling for the expenses content */\n.expenses-content {\n  --ion-background-color: #f4f4f4;\n  padding: 15px; /* Add padding to the content for spacing */\n}\n\n/* Card styling */\n.expenses-card {\n  background-color: #2c3e50;\n  color: #ecf0f1;\n  padding: 20px;\n  border-radius: 25px;\n  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Slight shadow for a modern look */\n}\n\n.expenses-title {\n  font-size: 1.5rem;\n  color: #ecf0f1;\n  text-align: center;\n}\n\n/* Center card on larger screens */\n.centered-card {\n  max-width: 600px;\n  margin: 0 auto;\n}\n\n.ion-label {\n  color: #ecf0f1;\n  font-weight: bold; /* Optional: make label text bold for better visibility */\n}\n\n.ion-input {\n  color: white;\n}\n\n/* Flexbox styling for expenses list, side-by-side cards for desktop */\n.expenses-grid {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-between;\n}\n\n/* Stack cards on smaller devices (iPad and smaller) */\n@media (max-width: 1024px) {\n  .expenses-grid {\n    flex-direction: column;\n  }\n\n  .expenses-card {\n    margin-bottom: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
