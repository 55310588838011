// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-title {
  white-space: normal;
  word-wrap: break-word;
  text-align: center;
  flex-grow: 1;
  padding: 0 16px;
}

ion-toolbar {
  display: flex;
  align-items: center;
}

ion-buttons {
  flex: 0 0 auto;
}

ion-title {
  flex: 1 1;
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/Components/I9Header.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,qBAAqB;EACrB,kBAAkB;EAClB,YAAY;EACZ,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,SAAO;EACP,kBAAkB;AACpB","sourcesContent":[".header-title {\n  white-space: normal;\n  word-wrap: break-word;\n  text-align: center;\n  flex-grow: 1;\n  padding: 0 16px;\n}\n\nion-toolbar {\n  display: flex;\n  align-items: center;\n}\n\nion-buttons {\n  flex: 0 0 auto;\n}\n\nion-title {\n  flex: 1;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
