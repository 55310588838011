import axios from "axios";
import { getToken } from "./authService";

// Utility function to format date
const formatDate = (date: Date) => {
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${month}-${day}-${year}`;
};

const findFolder = async (
  accessToken: string,
  driveId: string,
  parentFolderId: string,
  folderName: string
) => {
  try {
    const response = await axios.get(
      `https://graph.microsoft.com/v1.0/drives/${driveId}/items/${parentFolderId}/children`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );

    const folder = response.data.value.find(
      (item: any) => item.name === folderName && item.folder
    );
    return folder;
  } catch (error: any) {
    handleError(error);
    throw error;
  }
};

const createFolder = async (
  accessToken: string,
  driveId: string,
  parentFolderId: string,
  folderName: string
) => {
  try {
    const response = await axios.post(
      `https://graph.microsoft.com/v1.0/drives/${driveId}/items/${parentFolderId}/children`,
      {
        name: folderName,
        folder: {},
        "@microsoft.graph.conflictBehavior": "rename",
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (error: any) {
    handleError(error);
    throw error;
  }
};

const uploadFile = async (
  accessToken: string,
  driveId: string,
  parentFolderId: string,
  fileName: string,
  fileBlob: Blob
) => {
  try {
    const response = await axios.put(
      `https://graph.microsoft.com/v1.0/drives/${driveId}/items/${parentFolderId}:/${fileName}:/content`,
      fileBlob,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": fileBlob.type,
        },
      }
    );

    return response.data;
  } catch (error: any) {
    handleError(error);
    throw error;
  }
};

const handleError = (error: any) => {
  console.error("Error:", error.message);
  if (error.response) {
    console.error("Response Data:", error.response.data);
    console.error("Response Status:", error.response.status);
    console.error("Response Headers:", error.response.headers);
    alert(
      `Error: ${error.message}\nStatus: ${
        error.response.status
      }\nDetails: ${JSON.stringify(error.response.data)}`
    );
  } else {
    alert(`Error: ${error.message}`);
  }
};

export const uploadFilesAndPhotosToFolder = async (
  employeeName: string,
  files: any[],
  photos: { name: string; dataUrl: string }[]
) => {
  try {
    const accessToken = await getToken();
    const driveId =
      "b!5KywVeEld0y8NNhDfG0uXN-CacW2b1ZAs6PS9EVImUauWERqyueNQaOSRvZafpFk"; // Drive ID
    const pyrlFolderId = "01A2EJU32WPY7QDKJLZVDY7RJLV5AVMXLB"; // Pyrl - Employees folder ID

    // Find or create the "00-New Hire Paperwork" folder
    const newHireFolder = await findFolder(
      accessToken,
      driveId,
      pyrlFolderId,
      "00-New Hire Paperwork"
    );
    console.log("00-New Hire Paperwork Folder:", newHireFolder);

    if (newHireFolder) {
      const currentDate = formatDate(new Date());
      const folderName = `${employeeName.replace(
        /\s+/g,
        "-"
      )} - XX - ${currentDate}`;

      let newFolder = await findFolder(
        accessToken,
        driveId,
        newHireFolder.id,
        folderName
      );

      if (!newFolder) {
        // If the folder doesn't exist, create it
        newFolder = await createFolder(
          accessToken,
          driveId,
          newHireFolder.id,
          folderName
        );
        console.log("New Folder Created:", newFolder);
      }

      // Upload files
      for (const file of files) {
        if (file.url) {
          const response = await fetch(file.url);
          const blob = await response.blob();
          await uploadFile(accessToken, driveId, newFolder.id, file.name, blob);
        }
      }

      // Upload photos
      for (const photo of photos) {
        const response = await fetch(photo.dataUrl);
        const blob = await response.blob();
        await uploadFile(accessToken, driveId, newFolder.id, photo.name, blob);
      }

      alert("Upload Successful✅!");
    } else {
      alert("Folder not found.");
    }
  } catch (error: any) {
    handleError(error);
    alert("Failed to upload files and photos.");
  }
};
