import React, { useState, useRef, useEffect } from "react";
import {
  IonPage,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonSelectOption,
  IonSelect,
  IonBackButton,
  IonButtons,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonProgressBar,
  IonModal,
  IonText,
  IonIcon,
} from "@ionic/react";
import { PDFDocument } from "pdf-lib";
import SignatureCanvas from "react-signature-canvas";
import { useProgress } from "../Context/ProgressContext";
import { useHistory } from "react-router-dom";
import { formatDate } from "../Utils/dateUtils";
import { close } from "ionicons/icons";

const DirectDeposit: React.FC = () => {
  const { progressData, setProgressData, setDirectDepositPdfUrl } =
    useProgress();
  const [employeeName, setEmployeeName] = useState("");
  const [bankName, setBankName] = useState("");
  const [branch, setBranch] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [routingNumber, setRoutingNumber] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [accountType, setAccountType] = useState("");
  const [signature, setSignature] = useState<string | null>(null);
  const [directDepositPdfUrl, setDirectDepositPdfUrlState] = useState<
    string | null
  >(null);
  const [isPdfGenerated, setIsPdfGenerated] = useState(false);
  const [showSignatureModal, setShowSignatureModal] = useState(false);

  const sigCanvas = useRef<SignatureCanvas | null>(null);
  const history = useHistory();

  const calculateProgress = () => {
    let completedFields = 0;
    const totalFields = 8;

    if (employeeName) completedFields++;
    if (bankName) completedFields++;
    if (branch) completedFields++;
    if (city) completedFields++;
    if (state) completedFields++;
    if (routingNumber) completedFields++;
    if (accountNumber) completedFields++;
    if (accountType) completedFields++;

    return Math.round((completedFields / totalFields) * 100);
  };

  useEffect(() => {
    setProgressData((prev) => ({
      ...prev,
      DirectDeposit: calculateProgress(),
    }));
  }, [
    employeeName,
    bankName,
    branch,
    city,
    state,
    routingNumber,
    accountNumber,
    accountType,
    setProgressData,
  ]);

  const handleGeneratePdf = async () => {
    try {
      const response = await fetch("./Direct Deposit.pdf");
      if (!response.ok)
        throw new Error(`HTTP error! status: ${response.status}`);
      const pdfBuffer = await response.arrayBuffer();
      const pdfDoc = await PDFDocument.load(pdfBuffer);
      const form = pdfDoc.getForm();

      form.getTextField("Employee Name").setText(employeeName);
      form.getTextField("Print Name").setText(employeeName);
      form.getTextField("Bank Name").setText(bankName);
      form.getTextField("Branch").setText(branch);
      form.getTextField("City").setText(city);
      form.getTextField("State").setText(state);
      form.getTextField("Routing Number").setText(routingNumber);
      form.getTextField("Account Number").setText(accountNumber);
      form.getTextField("Date").setText(formatDate(new Date()));

      if (accountType === "checking") {
        form.getCheckBox("Checking").check();
      } else {
        form.getCheckBox("Savings").check();
      }

      if (signature) {
        const pngImageBytes = await fetch(signature).then((res) =>
          res.arrayBuffer()
        );
        const pngImage = await pdfDoc.embedPng(pngImageBytes);
        const page = pdfDoc.getPages()[0];
        const { width, height } = page.getSize();
        page.drawImage(pngImage, {
          x: width / 2 - 250,
          y: height / 2 - 250,
          width: 150,
          height: 40,
        });
      }

      form.flatten();
      const pdfBytes = await pdfDoc.save();
      const blob = new Blob([pdfBytes], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      setDirectDepositPdfUrlState(url);
      setDirectDepositPdfUrl(url);
      setIsPdfGenerated(true);
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };

  const handleAddSignatureToPdf = async () => {
    try {
      if (!directDepositPdfUrl || !signature) return;

      const response = await fetch(directDepositPdfUrl);
      const pdfBuffer = await response.arrayBuffer();
      const pdfDoc = await PDFDocument.load(pdfBuffer);
      const pngImageBytes = await fetch(signature).then((res) =>
        res.arrayBuffer()
      );
      const pngImage = await pdfDoc.embedPng(pngImageBytes);

      const page = pdfDoc.getPages()[0];
      const { width, height } = page.getSize();
      page.drawImage(pngImage, {
        x: width / 2 - 250,
        y: height / 2 - 250,
        width: 150,
        height: 40,
      });

      const pdfBytes = await pdfDoc.save();

      const blob = new Blob([pdfBytes], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      setDirectDepositPdfUrlState(url);
      setDirectDepositPdfUrl(url);

      setShowSignatureModal(false); // Close the modal
      history.push("/home"); // Navigate back to home
    } catch (error) {
      console.error("Error adding signature to PDF:", error);
    }
  };

  const clearSignature = () => {
    if (sigCanvas.current) {
      sigCanvas.current.clear();
      setSignature(null);
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/home" text="BACK" />
          </IonButtons>
          <IonTitle>Direct Deposit</IonTitle>
          <IonProgressBar
            value={progressData.DirectDeposit / 100}
            color="primary"
          />
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonCard>
                <IonCardHeader>
                  <IonCardTitle>Direct Deposit Authorization</IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                  <IonItem>
                    <IonLabel position="stacked">Employee Name</IonLabel>
                    <IonInput
                      value={employeeName}
                      onIonChange={(e: any) => setEmployeeName(e.detail.value)}
                    />
                  </IonItem>
                  <IonItem>
                    <IonLabel position="stacked">Bank Name</IonLabel>
                    <IonInput
                      value={bankName}
                      onIonChange={(e: any) => setBankName(e.detail.value)}
                    />
                  </IonItem>
                  <IonItem>
                    <IonLabel position="stacked">Branch</IonLabel>
                    <IonInput
                      value={branch}
                      onIonChange={(e: any) => setBranch(e.detail.value)}
                    />
                  </IonItem>
                  <IonItem>
                    <IonLabel position="stacked">City</IonLabel>
                    <IonInput
                      value={city}
                      onIonChange={(e: any) => setCity(e.detail.value)}
                    />
                  </IonItem>
                  <IonItem>
                    <IonLabel position="stacked">State</IonLabel>
                    <IonInput
                      value={state}
                      onIonChange={(e: any) => setState(e.detail.value)}
                    />
                  </IonItem>
                  <IonItem>
                    <IonLabel position="stacked">Routing Number</IonLabel>
                    <IonInput
                      value={routingNumber}
                      onIonChange={(e: any) => setRoutingNumber(e.detail.value)}
                    />
                  </IonItem>
                  <IonItem>
                    <IonLabel position="stacked">Account Number</IonLabel>
                    <IonInput
                      value={accountNumber}
                      onIonChange={(e: any) => setAccountNumber(e.detail.value)}
                    />
                  </IonItem>
                  <IonItem>
                    <IonLabel>Account Type</IonLabel>
                    <IonSelect
                      value={accountType}
                      onIonChange={(e) => setAccountType(e.detail.value)}
                    >
                      <IonSelectOption value="checking">
                        Checking
                      </IonSelectOption>
                      <IonSelectOption value="savings">Savings</IonSelectOption>
                    </IonSelect>
                  </IonItem>
                  <IonButton expand="full" onClick={handleGeneratePdf}>
                    Review Document
                  </IonButton>
                  {directDepositPdfUrl && (
                    <iframe
                      src={directDepositPdfUrl}
                      width="100%"
                      height="500px"
                      title="Direct Deposit PDF"
                      style={{ marginTop: "20px" }}
                    ></iframe>
                  )}
                  <IonButton
                    expand="full"
                    onClick={() => setShowSignatureModal(true)}
                    disabled={!isPdfGenerated}
                    style={{ marginTop: "10px" }}
                  >
                    Sign & Submit
                  </IonButton>
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonModal
          isOpen={showSignatureModal}
          onDidDismiss={() => setShowSignatureModal(false)}
        >
          <IonHeader>
            <IonToolbar>
              <IonTitle>Signature</IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={() => setShowSignatureModal(false)}>
                  <IonIcon icon={close} />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <IonGrid>
              <IonRow>
                <IonCol size="12" className="ion-text-center">
                  <IonText>
                    <h2>Please sign below</h2>
                  </IonText>
                  <div
                    style={{
                      position: "relative",
                      width: "100%",
                      height: "200px",
                    }}
                  >
                    <SignatureCanvas
                      ref={sigCanvas}
                      penColor="black"
                      canvasProps={{
                        width: 1500,
                        height: 200,
                        style: { backgroundColor: "lightgray" },
                        className: "sigCanvas",
                      }}
                      onEnd={() =>
                        setSignature(
                          sigCanvas.current
                            ?.getTrimmedCanvas()
                            .toDataURL("image/png") ?? null
                        )
                      }
                    />
                  </div>
                  <IonButton onClick={clearSignature}>Clear</IonButton>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size="12" className="ion-text-center">
                  <IonButton
                    onClick={handleAddSignatureToPdf}
                    disabled={!signature}
                  >
                    Submit
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonContent>
        </IonModal>
      </IonContent>
    </IonPage>
  );
};

export default DirectDeposit;
