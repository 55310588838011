import React, { useState, useCallback, useRef } from "react";
import { useHistory } from "react-router-dom";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonList,
  IonText,
  IonSelect,
  IonSelectOption,
  IonIcon,
  IonGrid,
  IonRow,
  IonCol,
  IonLoading,
  IonModal,
  IonButtons,
  useIonToast,
} from "@ionic/react";
import { trashOutline, close } from "ionicons/icons";
import { last, PDFDocument, rgb } from "pdf-lib";
import SignatureCanvas from "react-signature-canvas";
import { uploadExpensesToWebforms } from "../../Utils/SharePointExpenseApi"; // Use the new expense-specific API function
import "./Expenses.css"; // Import the custom CSS
import { disablePastDates } from "../../Utils/dateUtils";

interface Expense {
  title: string;
  date: string;
  amount: string;
  category: string;
  merchant: string;
  cityState: string;
  jobName: string;
  description?: string; // Optional field
}

// Helper function to format date as MM-DD-YYYY
const formatDate = (dateString: string): string => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${month}-${day}-${year}`;
};

const Expenses: React.FC = () => {
  const [firstName, setFirstName] = useState(""); // State for first name
  const [lastName, setLastName] = useState(""); // State for last name
  const [expenseDate, setExpenseDate] = useState("");
  const [expenseAmount, setExpenseAmount] = useState("");
  const [expenseCategory, setExpenseCategory] = useState<string | undefined>();
  const [merchant, setMerchant] = useState("");
  const [cityState, setCityState] = useState("");
  const [jobName, setJobName] = useState("");
  const [description, setDescription] = useState("");
  const [totalExpenses, setTotalExpenses] = useState(0);
  const [expensesList, setExpensesList] = useState<Expense[]>([]); // List of expenses
  const [expensePdfUrl, setExpensePdfUrl] = useState<string | null>(null);
  const [loading, setLoading] = useState(false); // Loading indicator
  const [signature, setSignature] = useState<string | null>(null); // For storing the signature
  const [showSignatureModal, setShowSignatureModal] = useState(false); // Signature modal state

  const history = useHistory();

  const sigCanvas = useRef<SignatureCanvas | null>(null);
  const [present] = useIonToast();

  const handleAmountChange = (e: any) => {
    let input = e.detail.value;

    const decimalIndex = input.indexOf(".");
    if (decimalIndex >= 0) {
      const [integerPart, decimalPart] = input.split(".");
      if (decimalPart.length > 2) {
        input = `${integerPart}.${decimalPart.slice(0, 2)}`;
      }
    }

    setExpenseAmount(input);
  };

  const handleKeyPress = (e: any) => {
    const char = String.fromCharCode(e.keyCode || e.which);

    if (e.key === "Backspace") {
      return;
    }

    const isNumber = /^[0-9]*$/.test(char);
    const isDecimal = char === "." && !expenseAmount.includes(".");

    if (!isNumber && !isDecimal) {
      e.preventDefault();
      return;
    }

    if (expenseAmount.includes(".")) {
      const [integerPart, decimalPart] = expenseAmount.split(".");
      if (decimalPart && decimalPart.length >= 2) {
        e.preventDefault();
      }
    }
  };

  const isFormValid =
    firstName.trim() !== "" &&
    lastName.trim() !== "" &&
    expenseDate.trim() !== "" &&
    expenseAmount.trim() !== "" &&
    expenseCategory !== undefined &&
    merchant.trim() !== "" &&
    cityState.trim() !== "" &&
    jobName.trim() !== "";

  const presentToast = (message: string) => {
    present({
      message,
      duration: 3000,
      position: "middle", // This ensures the toast is in the middle
      buttons: [
        {
          text: "OK",
          role: "cancel",
        },
      ],
    });
  };

  const handleAddExpense = () => {
    if (!isFormValid) {
      presentToast("Please fill out all required fields."); // Show toast in the middle
      return;
    }

    const newExpense = {
      title: `${firstName} ${lastName}`,
      date: expenseDate,
      amount: expenseAmount,
      category: expenseCategory || "",
      merchant,
      cityState,
      jobName,
      description,
    };
    setExpensesList((prevExpenses) => [...prevExpenses, newExpense]);
    setTotalExpenses((prev) => prev + parseFloat(expenseAmount || "0"));

    setExpenseDate("");
    setExpenseAmount("");
    setExpenseCategory(undefined);
    setMerchant("");
    setCityState("");
    setJobName("");
    setDescription("");
  };

  const handleDeleteExpense = (indexToDelete: number) => {
    const updatedExpenses = expensesList.filter(
      (_, index) => index !== indexToDelete
    );
    const expenseToDelete = expensesList[indexToDelete];
    setTotalExpenses(
      (prev) => prev - parseFloat(expenseToDelete.amount || "0")
    );
    setExpensesList(updatedExpenses);
  };

  const handleGeneratePdf = useCallback(async () => {
    const pdfDoc = await PDFDocument.create();
    const page = pdfDoc.addPage([600, 750]);

    const { width, height } = page.getSize();
    page.drawText("Expenses Report", {
      x: 50,
      y: height - 50,
      size: 20,
      color: rgb(0, 0, 0.8),
    });

    page.drawText("Employee Name", { x: 50, y: height - 80, size: 12 });
    page.drawText("Date", { x: 150, y: height - 80, size: 12 });
    page.drawText("Amount", { x: 250, y: height - 80, size: 12 });
    page.drawText("Category", { x: 350, y: height - 80, size: 12 });
    page.drawText("Merchant", { x: 450, y: height - 80, size: 12 });

    let currentY = height - 100;
    expensesList.forEach((expense, index) => {
      if (currentY < 100) {
        page.drawText("Continued...", { x: 50, y: 50, size: 10 });
        const newPage = pdfDoc.addPage([600, 750]);
        page.drawText(`Page ${index + 1}`, {
          x: 550,
          y: height - 30,
          size: 10,
        });
        currentY = height - 50;
      }

      page.drawText(expense.title, { x: 50, y: currentY, size: 10 });
      page.drawText(formatDate(expense.date), {
        x: 150,
        y: currentY,
        size: 10,
      });
      page.drawText(`$${parseFloat(expense.amount).toFixed(2)}`, {
        x: 250,
        y: currentY,
        size: 10,
      });
      page.drawText(expense.category, { x: 350, y: currentY, size: 10 });
      page.drawText(expense.merchant, { x: 450, y: currentY, size: 10 });

      currentY -= 20;
    });

    // Add total expenses to the PDF
    currentY -= 20; // Add some spacing before total
    page.drawText(`Total Expenses: $${totalExpenses.toFixed(2)}`, {
      x: 50,
      y: currentY,
      size: 14,
      color: rgb(0.8, 0, 0),
    });

    // Add footer text
    const footerText =
      "The information contained in this document is true and correct. I used all my Subsistence pay for the purposes of living away from home. I do not live within 75 miles of the job per my dispatch address provided.";
    const footerY = 30; // Position for footer
    page.drawText(footerText, {
      x: 50,
      y: footerY,
      size: 10,
      color: rgb(0, 0, 0),
      maxWidth: width - 100, // Set max width to avoid overflow
    });

    const pdfBytes = await pdfDoc.save();
    const blob = new Blob([pdfBytes], { type: "application/pdf" });
    const url = window.URL.createObjectURL(blob);
    setExpensePdfUrl(url);
  }, [expensesList, totalExpenses]);

  const handleSubmitExpenseReport = async () => {
    if (!expensePdfUrl) {
      presentToast("Please generate the PDF before submitting.");
      return;
    }

    try {
      setLoading(true);

      const response = await fetch(expensePdfUrl);
      const pdfBuffer = await response.arrayBuffer();
      const pdfDoc = await PDFDocument.load(pdfBuffer);

      if (signature) {
        const pngImageBytes = await fetch(signature).then((res) =>
          res.arrayBuffer()
        );
        const pngImage = await pdfDoc.embedPng(pngImageBytes);

        const page = pdfDoc.getPages()[0];
        const { width, height } = page.getSize();
        page.drawImage(pngImage, {
          x: width / 2 - 150,
          y: height - 350,
          width: 150,
          height: 50,
        });
      }

      const signedPdfBytes = await pdfDoc.save();
      const blob = new Blob([signedPdfBytes], { type: "application/pdf" });
      const signedUrl = window.URL.createObjectURL(blob);

      // Get current date in MM-DD-YYYY format
      const currentDate = formatDate(new Date().toISOString());

      // Dynamically create the file name with employee name and current date
      // Dynamically create the file name with last name, first name, and current date
      const fileName = `${lastName.replace(/\s+/g, "-")}_${firstName.replace(
        /\s+/g,
        "-"
      )}-Expense-Report-${currentDate}.pdf`;

      const files = [{ name: fileName, url: signedUrl }];
      const photos: any[] = [];

      // Call the function to upload the expenses to the Webforms folder
      await uploadExpensesToWebforms(`${firstName} ${lastName}`, files);

      presentToast("Expense report submitted successfully!");

      // Wait for a short delay and then redirect and refresh
      setTimeout(() => {
        history.push("/homeSelection"); // Redirect back to the home screen
        window.location.reload(); // Refresh the page
      }, 1500); // You can adjust the delay if needed (1.5 seconds here)
    } catch (error) {
      presentToast("Failed to submit the expense report.");
    } finally {
      setLoading(false);
    }
  };

  const clearSignature = () => {
    if (sigCanvas.current) {
      sigCanvas.current.clear();
      setSignature(null);
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Expense Tracker</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent className="expenses-content">
        <IonGrid>
          <IonRow>
            <IonCol sizeLg="6" sizeMd="12" sizeSm="12">
              <IonCard className="expenses-card centered-card">
                <IonCardHeader>
                  <IonCardTitle className="expenses-title">
                    Add Expense
                  </IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                  <IonItem className="item">
                    <IonLabel position="stacked">First Name</IonLabel>
                    <IonInput
                      placeholder="Enter first name"
                      value={firstName}
                      onIonChange={(e: any) => setFirstName(e.detail.value)}
                    />
                  </IonItem>
                  <IonItem className="item">
                    <IonLabel position="stacked">Last Name</IonLabel>
                    <IonInput
                      placeholder="Enter last name"
                      value={lastName}
                      onIonChange={(e: any) => setLastName(e.detail.value)}
                    />
                  </IonItem>

                  <IonItem className="item">
                    <IonLabel position="stacked">Expense Date</IonLabel>
                    <IonInput
                      type="date"
                      value={expenseDate}
                      onIonChange={(e: any) => setExpenseDate(e.detail.value)} // Update state as the user types
                      onIonBlur={() => {
                        if (disablePastDates(expenseDate)) {
                          presentToast(
                            "You can only file expenses for the previous week until Monday midnight."
                          );
                          setExpenseDate(""); // Clear the date field
                        }
                      }}
                      max="9999-12-31"
                    />
                  </IonItem>

                  <IonItem className="item">
                    <IonLabel position="stacked">Amount</IonLabel>
                    <IonInput
                      type="tel"
                      placeholder="Enter amount"
                      value={expenseAmount}
                      onIonInput={handleAmountChange}
                      onKeyPress={handleKeyPress}
                    />
                  </IonItem>

                  <IonItem className="item">
                    <IonLabel position="stacked">Category</IonLabel>
                    <IonSelect
                      value={expenseCategory}
                      placeholder="Select Category"
                      onIonChange={(e) => setExpenseCategory(e.detail.value)}
                    >
                      <IonSelectOption value="Gas">Gas</IonSelectOption>
                      <IonSelectOption value="Food">Food</IonSelectOption>
                      <IonSelectOption value="Hotel">Hotel</IonSelectOption>
                      <IonSelectOption value="Other">Other</IonSelectOption>
                    </IonSelect>
                  </IonItem>

                  <IonItem className="item">
                    <IonLabel position="stacked">Merchant</IonLabel>
                    <IonInput
                      placeholder="Enter merchant"
                      value={merchant}
                      onIonChange={(e: any) => setMerchant(e.detail.value)}
                    />
                  </IonItem>

                  <IonItem className="item">
                    <IonLabel position="stacked">City, State</IonLabel>
                    <IonInput
                      placeholder="Enter city & state"
                      value={cityState}
                      onIonChange={(e: any) => setCityState(e.detail.value)}
                    />
                  </IonItem>

                  <IonItem className="item">
                    <IonLabel position="stacked">Job Name</IonLabel>
                    <IonInput
                      placeholder="Enter job name"
                      value={jobName}
                      onIonInput={(e: any) => setJobName(e.detail.value)}
                    />
                  </IonItem>

                  <IonItem className="item">
                    <IonLabel position="stacked">
                      Description (Optional)
                    </IonLabel>
                    <IonInput
                      placeholder="Enter description"
                      value={description}
                      onIonChange={(e: any) => setDescription(e.detail.value)}
                    />
                  </IonItem>

                  <IonButton
                    expand="block"
                    color="primary"
                    onClick={handleAddExpense}
                    disabled={!isFormValid}
                  >
                    Add Expense
                  </IonButton>
                </IonCardContent>
              </IonCard>
            </IonCol>

            <IonCol sizeLg="6" sizeMd="12" sizeSm="12">
              <IonCard className="expenses-card centered-card">
                <IonCardHeader>
                  <IonCardTitle className="expenses-title">
                    Expenses List
                  </IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                  <IonList>
                    {expensesList.length === 0 ? (
                      <IonText style={{ color: "black" }}>
                        No expenses added yet.
                      </IonText>
                    ) : (
                      expensesList.map((expense, index) => (
                        <IonItem key={index}>
                          <IonLabel>
                            <IonText style={{ fontWeight: "bold" }}>
                              {expense.title} - {expense.category}
                            </IonText>
                            <p>{formatDate(expense.date)}</p>
                            <p>${parseFloat(expense.amount).toFixed(2)}</p>
                            <p>Merchant: {expense.merchant}</p>
                            <p>City & State: {expense.cityState}</p>
                            <p>Job: {expense.jobName}</p>
                            {expense.description && (
                              <p>Description: {expense.description}</p>
                            )}
                          </IonLabel>
                          <IonButton
                            fill="clear"
                            color="danger"
                            onClick={() => handleDeleteExpense(index)}
                          >
                            <IonIcon icon={trashOutline} />
                          </IonButton>
                        </IonItem>
                      ))
                    )}
                  </IonList>

                  {/* Display total expenses */}
                  <IonItem>
                    <IonLabel>Total Expenses:</IonLabel>
                    <IonText>${totalExpenses.toFixed(2)}</IonText>
                  </IonItem>

                  {/* Generate PDF Button */}
                  <IonButton expand="full" onClick={handleGeneratePdf}>
                    Generate PDF
                  </IonButton>

                  {expensePdfUrl && (
                    <iframe
                      src={expensePdfUrl}
                      width="100%"
                      height="500px"
                      title="Expense PDF"
                      style={{ marginTop: "20px" }}
                    ></iframe>
                  )}

                  {expensePdfUrl && (
                    <IonButton
                      expand="full"
                      color="success"
                      onClick={() => setShowSignatureModal(true)}
                      style={{ marginTop: "20px" }}
                    >
                      Sign and Submit to Accounting
                    </IonButton>
                  )}
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonLoading isOpen={loading} message={"Submitting..."} />

        {/* Signature Modal */}
        <IonModal
          isOpen={showSignatureModal}
          onDidDismiss={() => setShowSignatureModal(false)}
        >
          <IonHeader>
            <IonToolbar>
              <IonTitle>Signature</IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={() => setShowSignatureModal(false)}>
                  <IonIcon icon={close} />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <IonGrid>
              <IonRow>
                <IonCol size="12" className="ion-text-center">
                  <IonText>
                    <h2>
                      The information contained in this document is true and
                      correct. I used all my Subsistence pay for the purposes of
                      living away from home. I do not live within 75 miles of
                      the job per my dispatch address provided.
                    </h2>
                  </IonText>
                  <div
                    style={{
                      position: "relative",
                      width: "100%",
                      height: "200px",
                    }}
                  >
                    <SignatureCanvas
                      ref={sigCanvas}
                      penColor="black"
                      canvasProps={{
                        width: 1500,
                        height: 200,
                        style: { backgroundColor: "lightgray" },
                        className: "sigCanvas",
                      }}
                      onEnd={() =>
                        setSignature(
                          sigCanvas.current
                            ?.getTrimmedCanvas()
                            .toDataURL("image/png") ?? null
                        )
                      }
                    />
                  </div>
                  <IonButton onClick={clearSignature}>Clear</IonButton>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size="12" className="ion-text-center">
                  <IonButton
                    onClick={handleSubmitExpenseReport}
                    disabled={!signature}
                  >
                    Submit to Accounting
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonContent>
        </IonModal>
      </IonContent>
    </IonPage>
  );
};

export default Expenses;
