import React from "react";
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonBackButton,
  IonProgressBar,
} from "@ionic/react";
import { useProgress } from "../Context/ProgressContext";
import "./I9Header.css";

const I9Header: React.FC = () => {
  const { progressData } = useProgress();

  return (
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <IonBackButton defaultHref="/home" text="BACK" />
        </IonButtons>
        <IonTitle className="header-title">
          Please fill out the I-9 Form
        </IonTitle>
      </IonToolbar>
      <IonProgressBar value={progressData.I9 / 100} color="primary" />
    </IonHeader>
  );
};

export default I9Header;
