import React from "react";
import {
  IonPage,
  IonContent,
  IonButton,
  IonText,
  IonGrid,
  IonRow,
  IonCol,
  IonHeader,
  IonToolbar,
  IonTitle,
} from "@ionic/react";
import { useHistory } from "react-router-dom";
import "./HomeSelection.css"; // Import the custom CSS

const HomeSelection: React.FC = () => {
  const history = useHistory();

  return (
    <IonPage>
      <IonContent className="ion-content">
        <IonGrid className="ion-grid">
          {/* Button at the top */}
          <IonHeader>
            <IonToolbar>
              <IonTitle>Please Select an App</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonRow>
            {/* <IonCol> */}
            <div className="top-button">
              <IonButton
                expand="block"
                onClick={() => history.push("/newhire")}
                className="square-button"
                color="primary"
              >
                <IonText>New Employee Portal</IonText>
              </IonButton>
            </div>
            {/* </IonCol> */}
          </IonRow>

          {/* Spacer for the logo */}
          <IonRow className="logo-container"></IonRow>

          {/* Button at the bottom */}
          <IonRow>
            <IonCol>
              <div className="bottom-button">
                <IonButton
                  expand="block"
                  onClick={() => history.push("/expenses")}
                  className="square-button"
                  color="secondary"
                >
                  <IonText>Expense Reports</IonText>
                </IonButton>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default HomeSelection;
