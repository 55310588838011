import React, { useState, useRef, useEffect, useCallback } from "react";
import {
  IonPage,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonBackButton,
  IonButtons,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonProgressBar,
  IonModal,
  IonText,
  IonIcon,
} from "@ionic/react";
import { PDFDocument } from "pdf-lib";
import SignatureCanvas from "react-signature-canvas";
import { useProgress } from "../Context/ProgressContext";
import { useHistory } from "react-router-dom";
import { close } from "ionicons/icons";

const EmergencyContact: React.FC = () => {
  const { progressData, setProgressData, setEmergencyContactPdfUrl } =
    useProgress();
  const [employeeName, setEmployeeName] = useState("");
  const [contact1Name, setContact1Name] = useState("");
  const [contact1Relationship, setContact1Relationship] = useState("");
  const [contact1Phone1, setContact1Phone1] = useState("");
  const [contact1Phone2, setContact1Phone2] = useState("");
  const [contact1Address, setContact1Address] = useState("");
  const [contact2Name, setContact2Name] = useState("");
  const [contact2Relationship, setContact2Relationship] = useState("");
  const [contact2Phone1, setContact2Phone1] = useState("");
  const [contact2Phone2, setContact2Phone2] = useState("");
  const [contact2Address, setContact2Address] = useState("");
  const [contact3Name, setContact3Name] = useState("");
  const [contact3Relationship, setContact3Relationship] = useState("");
  const [contact3Phone1, setContact3Phone1] = useState("");
  const [contact3Phone2, setContact3Phone2] = useState("");
  const [contact3Address, setContact3Address] = useState("");
  const [contactCount, setContactCount] = useState(1);
  const [showSignatureModal, setShowSignatureModal] = useState(false);
  const [signature, setSignature] = useState<string | null>(null);
  const [emergencyContactPdfUrl, setEmergencyContactPdfUrlState] = useState<
    string | null
  >(null);

  const sigCanvas = useRef<SignatureCanvas | null>(null);
  const history = useHistory();

  const calculateProgress = () => {
    let completedFields = 0;
    const requiredFields = 5;

    if (employeeName) completedFields++;
    if (contact1Name) completedFields++;
    if (contact1Relationship) completedFields++;
    if (contact1Phone1) completedFields++;
    if (contact1Address) completedFields++;

    return Math.round((completedFields / requiredFields) * 100);
  };

  const handleGeneratePdf = useCallback(async () => {
    try {
      const response = await fetch("./Emergency Contact Form.pdf");
      if (!response.ok)
        throw new Error(`HTTP error! status: ${response.status}`);
      const pdfBuffer = await response.arrayBuffer();
      const pdfDoc = await PDFDocument.load(pdfBuffer);
      const form = pdfDoc.getForm();

      form.getTextField("FIRSTNAME_LASTNAME").setText(employeeName);
      form.getTextField("CONTACT1_NAME").setText(contact1Name);
      form.getTextField("CONTACT1_RELATIONSHIP").setText(contact1Relationship);
      form.getTextField("CONTANT1_PHONE1").setText(contact1Phone1);
      form.getTextField("CONTACT1_PHONE2").setText(contact1Phone2);
      form.getTextField("CONTACT1_ADDRESS").setText(contact1Address);
      if (contactCount > 1) {
        form.getTextField("CONTACT2_NAME").setText(contact2Name);
        form
          .getTextField("CONTACT2_RELATIONSHIP")
          .setText(contact2Relationship);
        form.getTextField("CONTACT2_PHONE1").setText(contact2Phone1);
        form.getTextField("CONTACT2_PHONE2").setText(contact2Phone2);
        form.getTextField("CONTACT2_ADDRESS").setText(contact2Address);
      }
      if (contactCount > 2) {
        form.getTextField("CONTACT3_NAME").setText(contact3Name);
        form
          .getTextField("CONTACT3_RELATIONSHIP")
          .setText(contact3Relationship);
        form.getTextField("CONTACT3_PHONE1").setText(contact3Phone1);
        form.getTextField("CONTACT3_PHONE2").setText(contact3Phone2);
        form.getTextField("CONTACT3_ADDRESS").setText(contact3Address);
      }

      form.flatten();
      const pdfBytes = await pdfDoc.save();
      const blob = new Blob([pdfBytes], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      setEmergencyContactPdfUrlState(url);
      setEmergencyContactPdfUrl(url);
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  }, [
    employeeName,
    contact1Name,
    contact1Relationship,
    contact1Phone1,
    contact1Phone2,
    contact1Address,
    contact2Name,
    contact2Relationship,
    contact2Phone1,
    contact2Phone2,
    contact2Address,
    contact3Name,
    contact3Relationship,
    contact3Phone1,
    contact3Phone2,
    contact3Address,
    contactCount,
    setEmergencyContactPdfUrl,
  ]);

  useEffect(() => {
    setProgressData((prev) => ({
      ...prev,
      EmergencyContact: calculateProgress(),
    }));
  }, [
    employeeName,
    contact1Name,
    contact1Relationship,
    contact1Phone1,
    contact1Address,
    contact2Name,
    contact2Relationship,
    contact2Phone1,
    contact2Phone2,
    contact2Address,
    contact3Name,
    contact3Relationship,
    contact3Phone1,
    contact3Phone2,
    contact3Address,
    setProgressData,
  ]);

  const handleAddSignatureToPdf = async () => {
    try {
      if (!emergencyContactPdfUrl || !signature) return;

      const response = await fetch(emergencyContactPdfUrl);
      const pdfBuffer = await response.arrayBuffer();
      const pdfDoc = await PDFDocument.load(pdfBuffer);
      const pngImageBytes = await fetch(signature).then((res) =>
        res.arrayBuffer()
      );
      const pngImage = await pdfDoc.embedPng(pngImageBytes);

      const page = pdfDoc.getPages()[0];
      const { width, height } = page.getSize();
      page.drawImage(pngImage, {
        x: width / 2 - 200,
        y: height / 2 - 250,
        width: 150,
        height: 40,
      });

      const pdfBytes = await pdfDoc.save();
      const blob = new Blob([pdfBytes], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      setEmergencyContactPdfUrlState(url);
      setEmergencyContactPdfUrl(url);

      setShowSignatureModal(false); // Close the modal
      history.push("/home"); // Navigate back to home
    } catch (error) {
      console.error("Error adding signature to PDF:", error);
    }
  };

  const clearSignature = () => {
    if (sigCanvas.current) {
      sigCanvas.current.clear();
      setSignature(null);
    }
  };

  const addContactCard = () => {
    if (contactCount < 3) {
      setContactCount(contactCount + 1);
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/home" text="BACK" />
          </IonButtons>
          <IonTitle>Emergency Contact</IonTitle>
          <IonProgressBar
            value={progressData.EmergencyContact / 100}
            color="primary"
          />
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonCard>
                <IonCardHeader>
                  <IonCardTitle>Emergency Contact Form</IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                  <IonItem>
                    <IonLabel position="stacked">
                      <strong>Employee Name</strong> (First and Last)
                    </IonLabel>
                    <IonInput
                      placeholder="Your name here..."
                      value={employeeName}
                      onIonChange={(e: any) => setEmployeeName(e.detail.value)}
                      required
                    />
                  </IonItem>
                </IonCardContent>
              </IonCard>

              <IonCard>
                <IonCardHeader>
                  <IonCardTitle>
                    <strong>Contact 1</strong>
                  </IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                  <IonItem>
                    <IonLabel position="stacked">
                      <strong>Contact 1 Name</strong>(First and Last)
                    </IonLabel>
                    <IonInput
                      value={contact1Name}
                      onIonChange={(e: any) => setContact1Name(e.detail.value)}
                      required
                    />
                  </IonItem>
                  <IonItem>
                    <IonLabel position="stacked">
                      <strong>Relationship</strong>
                    </IonLabel>
                    <IonInput
                      value={contact1Relationship}
                      onIonChange={(e: any) =>
                        setContact1Relationship(e.detail.value)
                      }
                      required
                    />
                  </IonItem>
                  <IonItem>
                    <IonLabel position="stacked">
                      <strong>Phone 1</strong>
                    </IonLabel>
                    <IonInput
                      value={contact1Phone1}
                      onIonChange={(e: any) =>
                        setContact1Phone1(e.detail.value)
                      }
                      required
                    />
                  </IonItem>
                  <IonItem>
                    <IonLabel position="stacked">
                      <strong>Phone 2 (optional)</strong>
                    </IonLabel>
                    <IonInput
                      value={contact1Phone2}
                      onIonChange={(e: any) =>
                        setContact1Phone2(e.detail.value)
                      }
                    />
                  </IonItem>
                  <IonItem>
                    <IonLabel position="stacked">
                      <strong>Address</strong>
                    </IonLabel>
                    <IonInput
                      value={contact1Address}
                      onIonChange={(e: any) =>
                        setContact1Address(e.detail.value)
                      }
                      required
                    />
                  </IonItem>
                </IonCardContent>
              </IonCard>

              {contactCount > 1 && (
                <IonCard>
                  <IonCardHeader>
                    <IonCardTitle>Contact 2</IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>
                    <IonItem>
                      <IonLabel position="stacked">Contact 2 Name</IonLabel>
                      <IonInput
                        value={contact2Name}
                        onIonChange={(e: any) =>
                          setContact2Name(e.detail.value)
                        }
                      />
                    </IonItem>
                    <IonItem>
                      <IonLabel position="stacked">
                        Contact 2 Relationship
                      </IonLabel>
                      <IonInput
                        value={contact2Relationship}
                        onIonChange={(e: any) =>
                          setContact2Relationship(e.detail.value)
                        }
                      />
                    </IonItem>
                    <IonItem>
                      <IonLabel position="stacked">Contact 2 Phone 1</IonLabel>
                      <IonInput
                        value={contact2Phone1}
                        onIonChange={(e: any) =>
                          setContact2Phone1(e.detail.value)
                        }
                      />
                    </IonItem>
                    <IonItem>
                      <IonLabel position="stacked">
                        Contact 2 Phone 2 (optional)
                      </IonLabel>
                      <IonInput
                        value={contact2Phone2}
                        onIonChange={(e: any) =>
                          setContact2Phone2(e.detail.value)
                        }
                      />
                    </IonItem>
                    <IonItem>
                      <IonLabel position="stacked">Contact 2 Address</IonLabel>
                      <IonInput
                        value={contact2Address}
                        onIonChange={(e: any) =>
                          setContact2Address(e.detail.value)
                        }
                      />
                    </IonItem>
                  </IonCardContent>
                </IonCard>
              )}

              {contactCount > 2 && (
                <IonCard>
                  <IonCardHeader>
                    <IonCardTitle>Contact 3</IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>
                    <IonItem>
                      <IonLabel position="stacked">Contact 3 Name</IonLabel>
                      <IonInput
                        value={contact3Name}
                        onIonChange={(e: any) =>
                          setContact3Name(e.detail.value)
                        }
                      />
                    </IonItem>
                    <IonItem>
                      <IonLabel position="stacked">
                        Contact 3 Relationship
                      </IonLabel>
                      <IonInput
                        value={contact3Relationship}
                        onIonChange={(e: any) =>
                          setContact3Relationship(e.detail.value)
                        }
                      />
                    </IonItem>
                    <IonItem>
                      <IonLabel position="stacked">Contact 3 Phone 1</IonLabel>
                      <IonInput
                        value={contact3Phone1}
                        onIonChange={(e: any) =>
                          setContact3Phone1(e.detail.value)
                        }
                      />
                    </IonItem>
                    <IonItem>
                      <IonLabel position="stacked">
                        Contact 3 Phone 2 (optional)
                      </IonLabel>
                      <IonInput
                        value={contact3Phone2}
                        onIonChange={(e: any) =>
                          setContact3Phone2(e.detail.value)
                        }
                      />
                    </IonItem>
                    <IonItem>
                      <IonLabel position="stacked">Contact 3 Address</IonLabel>
                      <IonInput
                        value={contact3Address}
                        onIonChange={(e: any) =>
                          setContact3Address(e.detail.value)
                        }
                      />
                    </IonItem>
                  </IonCardContent>
                </IonCard>
              )}

              {contactCount < 3 && (
                <IonButton
                  expand="full"
                  onClick={addContactCard}
                  color="success"
                >
                  <IonLabel>+ ADD ANOTHER CONTACT </IonLabel>
                </IonButton>
              )}

              {emergencyContactPdfUrl && (
                <iframe
                  src={emergencyContactPdfUrl}
                  width="100%"
                  height="500px"
                  title="Emergency Contact PDF"
                  style={{ marginTop: "20px" }}
                ></iframe>
              )}
              <IonButton
                expand="full"
                onClick={handleGeneratePdf}
                style={{ marginTop: "10px" }}
              >
                Review Document
              </IonButton>
              <IonButton
                expand="full"
                onClick={() => setShowSignatureModal(true)}
                disabled={!emergencyContactPdfUrl}
                style={{ marginTop: "10px" }}
              >
                Sign & Submit
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonModal
          isOpen={showSignatureModal}
          onDidDismiss={() => setShowSignatureModal(false)}
        >
          <IonHeader>
            <IonToolbar>
              <IonTitle>Signature</IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={() => setShowSignatureModal(false)}>
                  <IonIcon icon={close} />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <IonGrid>
              <IonRow>
                <IonCol size="12" className="ion-text-center">
                  <IonText>
                    <h2>Please sign below</h2>
                  </IonText>
                  <div
                    style={{
                      position: "relative",
                      width: "100%",
                      height: "200px",
                    }}
                  >
                    <SignatureCanvas
                      ref={sigCanvas}
                      penColor="black"
                      canvasProps={{
                        width: 1500,
                        height: 200,
                        style: { backgroundColor: "lightgray" },
                        className: "sigCanvas",
                      }}
                      onEnd={() =>
                        setSignature(
                          sigCanvas.current
                            ?.getTrimmedCanvas()
                            .toDataURL("image/png") ?? null
                        )
                      }
                    />
                  </div>
                  <IonButton onClick={clearSignature}>Clear</IonButton>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size="12" className="ion-text-center">
                  <IonButton
                    onClick={handleAddSignatureToPdf}
                    disabled={!signature}
                  >
                    Submit
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonContent>
        </IonModal>
      </IonContent>
    </IonPage>
  );
};

export default EmergencyContact;
