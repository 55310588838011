// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* CustomModal.css */
.custom-modal {
  --width: 80%;
  --height: 80%;
  --border-radius: 10px;
}

.custom-modal-content {
  width: 100%;
  height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/CustomModal.css"],"names":[],"mappings":"AAAA,oBAAoB;AACpB;EACE,YAAY;EACZ,aAAa;EACb,qBAAqB;AACvB;;AAEA;EACE,WAAW;EACX,YAAY;AACd","sourcesContent":["/* CustomModal.css */\n.custom-modal {\n  --width: 80%;\n  --height: 80%;\n  --border-radius: 10px;\n}\n\n.custom-modal-content {\n  width: 100%;\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
