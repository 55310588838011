import React, { createContext, useContext, useState, ReactNode } from "react";

export interface ProgressData {
  W4: number;
  I9: number;
  DirectDeposit: number;
  EmergencyContact: number;
  Certifications: number;
}

export interface Certification {
  name: string;
  front: string | null;
  back: string | null;
}

export interface I9DocumentPhoto {
  documentType: string;
  front: string | null;
  back: string | null;
}

export interface ProgressContextProps {
  progressData: ProgressData;
  w4PdfUrl: string | null;
  i9PdfUrl: string | null;
  directDepositPdfUrl: string | null;
  emergencyContactPdfUrl: string | null;
  employeeName: string;
  certificationsData: Certification[];
  i9DocumentPhotosData: I9DocumentPhoto[];
  setProgressData: React.Dispatch<React.SetStateAction<ProgressData>>;
  setW4PdfUrl: React.Dispatch<React.SetStateAction<string | null>>;
  setI9PdfUrl: React.Dispatch<React.SetStateAction<string | null>>;
  setDirectDepositPdfUrl: React.Dispatch<React.SetStateAction<string | null>>;
  setEmergencyContactPdfUrl: React.Dispatch<
    React.SetStateAction<string | null>
  >;
  setEmployeeName: React.Dispatch<React.SetStateAction<string>>;
  setCertificationsData: React.Dispatch<React.SetStateAction<Certification[]>>;
  setI9DocumentPhotosData: React.Dispatch<
    React.SetStateAction<I9DocumentPhoto[]>
  >;
}

const ProgressContext = createContext<ProgressContextProps | undefined>(
  undefined
);

export const useProgress = () => {
  const context = useContext(ProgressContext);
  if (!context) {
    throw new Error("useProgress must be used within a ProgressProvider");
  }
  return context;
};

export const ProgressProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [progressData, setProgressData] = useState<ProgressData>({
    W4: 0,
    I9: 0,
    DirectDeposit: 0,
    EmergencyContact: 0,
    Certifications: 0,
  });
  const [w4PdfUrl, setW4PdfUrl] = useState<string | null>(null);
  const [i9PdfUrl, setI9PdfUrl] = useState<string | null>(null);
  const [directDepositPdfUrl, setDirectDepositPdfUrl] = useState<string | null>(
    null
  );
  const [emergencyContactPdfUrl, setEmergencyContactPdfUrl] = useState<
    string | null
  >(null);
  const [employeeName, setEmployeeName] = useState<string>("");
  const [certificationsData, setCertificationsData] = useState<Certification[]>(
    []
  );
  const [i9DocumentPhotosData, setI9DocumentPhotosData] = useState<
    I9DocumentPhoto[]
  >([]);

  return (
    <ProgressContext.Provider
      value={{
        progressData,
        w4PdfUrl,
        i9PdfUrl,
        directDepositPdfUrl,
        emergencyContactPdfUrl,
        employeeName,
        certificationsData,
        i9DocumentPhotosData,
        setProgressData,
        setW4PdfUrl,
        setI9PdfUrl,
        setDirectDepositPdfUrl,
        setEmergencyContactPdfUrl,
        setEmployeeName,
        setCertificationsData,
        setI9DocumentPhotosData,
      }}
    >
      {children}
    </ProgressContext.Provider>
  );
};
