import axios from "axios";

export const getToken = async () => {
  try {
    const response = await axios.get(
      "https://bahnmillerconstructionapi.com/getToken"
    ); // Ensure the correct port
    return response.data.accessToken;
  } catch (error) {
    console.error("Error getting token:", error);
    throw new Error("Error getting token");
  }
};
