import React, { useState, useRef, useEffect } from "react";
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCheckbox,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonModal,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
} from "@ionic/react";
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import { PDFDocument, StandardFonts } from "pdf-lib";
import SignatureCanvas from "react-signature-canvas";
import {
  sendOutline,
  cameraOutline,
  trashOutline,
  close,
} from "ionicons/icons";
import { useHistory } from "react-router-dom";
import { useProgress, I9DocumentPhoto } from "../Context/ProgressContext";
import "./I9.css";
import I9Header from "../Components/I9Header";
import { uploadFilesAndPhotosToFolder } from "../Utils/SharePointAPI"; // Assuming you have this utility for uploading files

const I9: React.FC = () => {
  const {
    setProgressData,
    i9PdfUrl,
    setI9PdfUrl,
    setI9DocumentPhotosData,
    i9DocumentPhotosData,
    employeeName, // Assuming employeeName is available from the Progress context
  } = useProgress();
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [middleInitial, setMiddleInitial] = useState("");
  const [otherLastNames, setOtherLastNames] = useState("");
  const [address, setAddress] = useState("");
  const [aptNumber, setAptNumber] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [dob, setDob] = useState("");
  const [ssn, setSsn] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [citizenshipStatus, setCitizenshipStatus] = useState("");
  const [uscisNumber, setUscisNumber] = useState("");
  const [i94Number, setI94Number] = useState("");
  const [foreignPassportNumber, setForeignPassportNumber] = useState("");
  const [countryOfIssuance, setCountryOfIssuance] = useState("");
  const [signature, setSignature] = useState<any>(null);
  const [driverLicensePhoto, setDriverLicensePhoto] = useState<string | null>(
    null
  );
  const [ssCardPhoto, setSsCardPhoto] = useState<string | null>(null);
  const [showPhotoModal, setShowPhotoModal] = useState(false);
  const [photoToShow, setPhotoToShow] = useState<string | null>(null);
  const [showSignatureModal, setShowSignatureModal] = useState(false);
  const [invalidFields, setInvalidFields] = useState<string[]>([]);

  // New state for document selection
  const [listASelected, setListASelected] = useState(false);
  const [listBSelected, setListBSelected] = useState(false);
  const [listADocument, setListADocument] = useState("");
  const [listBDocument1, setListBDocument1] = useState("");
  const [listBDocument2, setListBDocument2] = useState("");

  const sigCanvas = useRef<SignatureCanvas | null>(null);
  const history = useHistory();

  const today = new Date();
  const formattedDate = `${(today.getMonth() + 1)
    .toString()
    .padStart(2, "0")}/${today
    .getDate()
    .toString()
    .padStart(2, "0")}/${today.getFullYear()}`;

  const calculateProgress = () => {
    let completedFields = 0;
    const totalFields = 12; // Adjust total fields if necessary

    if (lastName) completedFields++;
    if (firstName) completedFields++;
    if (address) completedFields++;
    if (city) completedFields++;
    if (state) completedFields++;
    if (zip) completedFields++;
    if (dob) completedFields++;
    if (ssn) completedFields++;
    if (email) completedFields++;
    if (phone) completedFields++;
    if (citizenshipStatus) completedFields++;
    if (citizenshipStatus === "3" && uscisNumber) completedFields++;
    if (
      citizenshipStatus === "4" &&
      (i94Number || (foreignPassportNumber && countryOfIssuance))
    )
      completedFields++;
    if (signature) completedFields++;

    return Math.round((completedFields / totalFields) * 100);
  };

  useEffect(() => {
    setProgressData((prev) => ({ ...prev, I9: calculateProgress() }));
  }, [
    lastName,
    firstName,
    address,
    city,
    state,
    zip,
    dob,
    ssn,
    email,
    phone,
    citizenshipStatus,
    uscisNumber,
    i94Number,
    foreignPassportNumber,
    countryOfIssuance,
    signature,
    setProgressData,
  ]);

  const handleSsnChange = (e: any) => {
    let input = e.detail.value;
    const numericValue = input.replace(/\D/g, "");
    setSsn(numericValue);
  };

  const validateForm = () => {
    const requiredFields = [
      "lastName",
      "firstName",
      "address",
      "city",
      "state",
      "zip",
      "dob",
      "ssn",
      "email",
      "phone",
      "citizenshipStatus",
    ];

    if (citizenshipStatus === "3") {
      requiredFields.push("uscisNumber");
    } else if (citizenshipStatus === "4") {
      requiredFields.push("i94Number");
      requiredFields.push("foreignPassportNumber");
      requiredFields.push("countryOfIssuance");
    }

    // Check for the document photos based on selection
    if (listASelected) {
      if (!i9DocumentPhotosData[0]?.front) {
        requiredFields.push("driverLicensePhoto");
      }
    } else if (listBSelected) {
      if (!i9DocumentPhotosData[0]?.front) {
        requiredFields.push("driverLicensePhoto");
      }
      if (!i9DocumentPhotosData[1]?.front) {
        requiredFields.push("ssCardPhoto");
      }
    }

    const invalidFields = requiredFields.filter((field) => !eval(field));

    setInvalidFields(invalidFields);

    return invalidFields.length === 0;
  };

  const handleGeneratePdf = async () => {
    if (!validateForm()) {
      alert("Please fill out all required fields.");
      return;
    }

    try {
      const response = await fetch("/IRS - Form I-9 - Exp 07-31-2026.pdf");
      if (!response.ok)
        throw new Error(`HTTP error! status: ${response.status}`);
      const pdfBuffer = await response.arrayBuffer();
      const pdfDoc = await PDFDocument.load(pdfBuffer);
      const form = pdfDoc.getForm();
      const helveticaFont = await pdfDoc.embedStandardFont(
        StandardFonts.Helvetica
      );

      // Section 1 - Employee Information
      form.getTextField("Last Name (Family Name)").setText(lastName);
      form.getTextField("First Name Given Name").setText(firstName);
      form
        .getTextField("Employee Middle Initial (if any)")
        .setText(middleInitial);
      form
        .getTextField("Employee Other Last Names Used (if any)")
        .setText(otherLastNames);
      form.getTextField("Address Street Number and Name").setText(address);
      form.getTextField("Apt Number (if any)").setText(aptNumber);
      form.getTextField("City or Town").setText(city);
      form.getDropdown("State").select(state);
      form.getTextField("ZIP Code").setText(zip);
      form.getTextField("Date of Birth mmddyyyy").setText(dob);
      form.getTextField("US Social Security Number").setText(ssn);
      form.getTextField("Employees E-mail Address").setText(email);
      form.getTextField("Telephone Number").setText(phone);

      if (citizenshipStatus === "1") {
        form.getCheckBox("CB_1").check();
      } else if (citizenshipStatus === "2") {
        form.getCheckBox("CB_2").check();
      } else if (citizenshipStatus === "3") {
        form.getCheckBox("CB_3").check();
        form.getTextField("USCIS A-Number").setText(uscisNumber);
      } else if (citizenshipStatus === "4") {
        form.getCheckBox("CB_4").check();
        form.getTextField("Form I-94 Admission Number").setText(i94Number);
        form
          .getTextField("Foreign Passport Number and Country of Issuance")
          .setText(`${foreignPassportNumber}, ${countryOfIssuance}`);
      }

      form.getTextField("Today's Date mmddyyy").setText(formattedDate);

      // Section 2 - Employer or Authorized Representative Information
      form.getTextField("FirstDayEmployed mmddyyyy").setText(formattedDate);
      form.getTextField("S2 Todays Date mmddyyyy").setText(formattedDate);
      form
        .getTextField(
          "Last Name First Name and Title of Employer or Authorized Representative"
        )
        .setText(`Bahnmiller, Kevin | President`);
      form
        .getTextField("Employers Business or Org Name")
        .setText(`Bahnmiller Construction, Inc.`);
      form
        .getTextField("Employers Business or Org Address")
        .setText(`P.O Box 12604 Everett, WA 98206`);

      form.flatten();
      const pdfBytes = await pdfDoc.save();

      const blob = new Blob([pdfBytes], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      setI9PdfUrl(url); // Store the I-9 PDF URL in context
    } catch (error) {
      console.error("Error modifying PDF:", error);
    }
  };

  const handleAddSignatureToPdf = async () => {
    try {
      if (!i9PdfUrl || !signature) return;

      const response = await fetch(i9PdfUrl);
      const pdfBuffer = await response.arrayBuffer();
      const pdfDoc = await PDFDocument.load(pdfBuffer);
      const pngImageBytes = await fetch(signature).then((res) =>
        res.arrayBuffer()
      );
      const pngImage = await pdfDoc.embedPng(pngImageBytes);

      const page = pdfDoc.getPages()[0];
      const { width, height } = page.getSize();
      page.drawImage(pngImage, {
        x: width / 2 - 200,
        y: height / 2 + 30,
        width: 60,
        height: 15,
      });

      const pdfBytes = await pdfDoc.save();

      const blob = new Blob([pdfBytes], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      setI9PdfUrl(url); // Update the I-9 PDF URL with the signed PDF
      setShowSignatureModal(false); // Close the signature modal
      history.push("/home"); // Navigate back to the home page
    } catch (error) {
      console.error("Error adding signature to PDF:", error);
    }
  };

  const clearSignature = () => {
    if (sigCanvas.current) {
      sigCanvas.current.clear();
      setSignature(null);
    }
  };

  const takePhoto = async (index: number, field: "front" | "back") => {
    try {
      const photo = await Camera.getPhoto({
        resultType: CameraResultType.DataUrl,
        source: CameraSource.Camera,
        quality: 100,
      });

      const updatedPhotos = [...i9DocumentPhotosData];
      const documentType =
        listASelected && index === 0
          ? listADocument
          : listBSelected && index === 0
          ? listBDocument1
          : listBSelected && index === 1
          ? listBDocument2
          : "";

      const photoName = `${documentType}-${field}`;

      if (!updatedPhotos[index]) {
        updatedPhotos[index] = {
          documentType: photoName,
          front: null,
          back: null,
        };
      } else {
        updatedPhotos[index].documentType = photoName;
      }

      updatedPhotos[index][field] = photo.dataUrl ?? null;

      setI9DocumentPhotosData(updatedPhotos);
    } catch (error: any) {
      if (error.message !== "User cancelled photos app") {
        console.error("Error taking photo:", error);
      }
    }
  };

  const deletePhoto = (index: number, field: "front" | "back") => {
    const updatedPhotos = [...i9DocumentPhotosData];
    if (updatedPhotos[index]) {
      updatedPhotos[index][field] = null;
      setI9DocumentPhotosData(updatedPhotos);
    }
  };
  const handleDobChange = (e: any) => {
    let input = e.detail.value;

    // Remove all non-numeric characters
    input = input.replace(/\D/g, "");

    // Automatically add slashes as the user types
    if (input.length > 2 && input.length <= 4) {
      input = `${input.slice(0, 2)}/${input.slice(2)}`;
    } else if (input.length > 4) {
      input = `${input.slice(0, 2)}/${input.slice(2, 4)}/${input.slice(4, 8)}`;
    }

    // Update the DOB state with the formatted value
    setDob(input);
  };

  return (
    <IonPage>
      <I9Header />
      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol>
              {/* Step: 1 Enter Personal Information */}
              <IonCard>
                <IonCardHeader>
                  <IonCardTitle>
                    Employee Information and Attestation
                  </IonCardTitle>
                  <IonCardSubtitle>
                    <strong>
                      Section 1: Employee Information and Attestation
                    </strong>
                  </IonCardSubtitle>
                  <IonCardSubtitle>
                    <strong>
                      Does your name match the name on your social security
                      card?
                    </strong>
                    &nbsp;If not, to ensure you get credit for your earnings,
                    contact SSA at 800-772-1213 or go to www.ssa.gov.
                  </IonCardSubtitle>
                </IonCardHeader>
                <IonCardContent>
                  <IonItem
                    className={
                      invalidFields.includes("lastName") ? "invalid-field" : ""
                    }
                  >
                    <IonLabel position="stacked">
                      Last Name (Family Name)
                    </IonLabel>
                    <IonInput
                      value={lastName}
                      onIonChange={(e: any) => setLastName(e.detail.value)}
                      placeholder="Last Name"
                    />
                  </IonItem>
                  <IonItem
                    className={
                      invalidFields.includes("firstName") ? "invalid-field" : ""
                    }
                  >
                    <IonLabel position="stacked">
                      First Name (Given Name)
                    </IonLabel>
                    <IonInput
                      value={firstName}
                      onIonChange={(e: any) => setFirstName(e.detail.value)}
                      placeholder="First Name"
                    />
                  </IonItem>
                  <IonItem>
                    <IonLabel position="stacked">
                      Middle Initial (if any)
                    </IonLabel>
                    <IonInput
                      value={middleInitial}
                      onIonChange={(e: any) => setMiddleInitial(e.detail.value)}
                      placeholder="Middle Initial"
                      maxlength={1}
                    />
                  </IonItem>
                  <IonItem>
                    <IonLabel position="stacked">
                      Other Last Names Used (if any)
                    </IonLabel>
                    <IonInput
                      value={otherLastNames}
                      onIonChange={(e: any) =>
                        setOtherLastNames(e.detail.value)
                      }
                      placeholder="Other Last Names"
                    />
                  </IonItem>
                  <IonItem
                    className={
                      invalidFields.includes("address") ? "invalid-field" : ""
                    }
                  >
                    <IonLabel position="stacked">
                      Address (Street Number and Name)
                    </IonLabel>
                    <IonInput
                      value={address}
                      onIonChange={(e: any) => setAddress(e.detail.value)}
                      placeholder="Address"
                    />
                  </IonItem>
                  <IonItem>
                    <IonLabel position="stacked">Apt. Number (if any)</IonLabel>
                    <IonInput
                      value={aptNumber}
                      onIonChange={(e: any) => setAptNumber(e.detail.value)}
                      placeholder="Apt. Number"
                    />
                  </IonItem>
                  <IonItem
                    className={
                      invalidFields.includes("city") ? "invalid-field" : ""
                    }
                  >
                    <IonLabel position="stacked">City or Town</IonLabel>
                    <IonInput
                      value={city}
                      onIonChange={(e: any) => setCity(e.detail.value)}
                      placeholder="City"
                    />
                  </IonItem>
                  <IonItem
                    className={
                      invalidFields.includes("state") ? "invalid-field" : ""
                    }
                  >
                    <IonLabel position="stacked">State</IonLabel>
                    <IonSelect
                      value={state}
                      placeholder="Select State"
                      onIonChange={(e: any) => setState(e.detail.value)}
                    >
                      <IonSelectOption value="AL">Alabama</IonSelectOption>
                      <IonSelectOption value="AK">Alaska</IonSelectOption>
                      <IonSelectOption value="AZ">Arizona</IonSelectOption>
                      <IonSelectOption value="AR">Arkansas</IonSelectOption>
                      <IonSelectOption value="CA">California</IonSelectOption>
                      <IonSelectOption value="CO">Colorado</IonSelectOption>
                      <IonSelectOption value="CT">Connecticut</IonSelectOption>
                      <IonSelectOption value="DE">Delaware</IonSelectOption>
                      <IonSelectOption value="DC">
                        District Of Columbia
                      </IonSelectOption>
                      <IonSelectOption value="FL">Florida</IonSelectOption>
                      <IonSelectOption value="GA">Georgia</IonSelectOption>
                      <IonSelectOption value="HI">Hawaii</IonSelectOption>
                      <IonSelectOption value="ID">Idaho</IonSelectOption>
                      <IonSelectOption value="IL">Illinois</IonSelectOption>
                      <IonSelectOption value="IN">Indiana</IonSelectOption>
                      <IonSelectOption value="IA">Iowa</IonSelectOption>
                      <IonSelectOption value="KS">Kansas</IonSelectOption>
                      <IonSelectOption value="KY">Kentucky</IonSelectOption>
                      <IonSelectOption value="LA">Louisiana</IonSelectOption>
                      <IonSelectOption value="ME">Maine</IonSelectOption>
                      <IonSelectOption value="MD">Maryland</IonSelectOption>
                      <IonSelectOption value="MA">
                        Massachusetts
                      </IonSelectOption>
                      <IonSelectOption value="MI">Michigan</IonSelectOption>
                      <IonSelectOption value="MN">Minnesota</IonSelectOption>
                      <IonSelectOption value="MS">Mississippi</IonSelectOption>
                      <IonSelectOption value="MO">Missouri</IonSelectOption>
                      <IonSelectOption value="MT">Montana</IonSelectOption>
                      <IonSelectOption value="NE">Nebraska</IonSelectOption>
                      <IonSelectOption value="NV">Nevada</IonSelectOption>
                      <IonSelectOption value="NH">
                        New Hampshire
                      </IonSelectOption>
                      <IonSelectOption value="NJ">New Jersey</IonSelectOption>
                      <IonSelectOption value="NM">New Mexico</IonSelectOption>
                      <IonSelectOption value="NY">New York</IonSelectOption>
                      <IonSelectOption value="NC">
                        North Carolina
                      </IonSelectOption>
                      <IonSelectOption value="ND">North Dakota</IonSelectOption>
                      <IonSelectOption value="OH">Ohio</IonSelectOption>
                      <IonSelectOption value="OK">Oklahoma</IonSelectOption>
                      <IonSelectOption value="OR">Oregon</IonSelectOption>
                      <IonSelectOption value="PA">Pennsylvania</IonSelectOption>
                      <IonSelectOption value="RI">Rhode Island</IonSelectOption>
                      <IonSelectOption value="SC">
                        South Carolina
                      </IonSelectOption>
                      <IonSelectOption value="SD">South Dakota</IonSelectOption>
                      <IonSelectOption value="TN">Tennessee</IonSelectOption>
                      <IonSelectOption value="TX">Texas</IonSelectOption>
                      <IonSelectOption value="UT">Utah</IonSelectOption>
                      <IonSelectOption value="VT">Vermont</IonSelectOption>
                      <IonSelectOption value="VA">Virginia</IonSelectOption>
                      <IonSelectOption value="WA">Washington</IonSelectOption>
                      <IonSelectOption value="WV">
                        West Virginia
                      </IonSelectOption>
                      <IonSelectOption value="WI">Wisconsin</IonSelectOption>
                      <IonSelectOption value="WY">Wyoming</IonSelectOption>
                    </IonSelect>
                  </IonItem>
                  <IonItem
                    className={
                      invalidFields.includes("zip") ? "invalid-field" : ""
                    }
                  >
                    <IonLabel position="stacked">ZIP Code</IonLabel>
                    <IonInput
                      value={zip}
                      onIonChange={(e: any) => setZip(e.detail.value)}
                      placeholder="ZIP"
                      maxlength={5}
                    />
                  </IonItem>
                  <IonItem
                    className={
                      invalidFields.includes("dob") ? "invalid-field" : ""
                    }
                  >
                    <IonLabel position="stacked">
                      Date of Birth (mm/dd/yyyy)
                    </IonLabel>
                    <IonInput
                      value={dob}
                      onIonChange={handleDobChange}
                      placeholder="MM/DD/YYYY"
                      maxlength={10}
                    />
                  </IonItem>

                  <IonItem
                    className={
                      invalidFields.includes("ssn") ? "invalid-field" : ""
                    }
                  >
                    <IonLabel position="stacked">
                      U.S. Social Security Number
                    </IonLabel>
                    <IonInput
                      value={ssn}
                      onIonChange={handleSsnChange}
                      placeholder="XXXXXXXXX"
                      type="tel"
                      maxlength={9}
                    />
                  </IonItem>
                  <IonItem
                    className={
                      invalidFields.includes("email") ? "invalid-field" : ""
                    }
                  >
                    <IonLabel position="stacked">
                      Employee's Email Address
                    </IonLabel>
                    <IonInput
                      value={email}
                      onIonChange={(e: any) => setEmail(e.detail.value)}
                      placeholder="Email"
                    />
                  </IonItem>
                  <IonItem
                    className={
                      invalidFields.includes("phone") ? "invalid-field" : ""
                    }
                  >
                    <IonLabel position="stacked">
                      Employee's Telephone Number
                    </IonLabel>
                    <IonInput
                      value={phone}
                      onIonChange={(e: any) => setPhone(e.detail.value)}
                      placeholder="Phone Number"
                    />
                  </IonItem>
                </IonCardContent>
                <IonCard>
                  <IonCardHeader>
                    <IonCardTitle>
                      Attestation of Citizenship or Immigration Status
                    </IonCardTitle>
                    <IonCardSubtitle>
                      Please select one of the following options
                    </IonCardSubtitle>
                  </IonCardHeader>
                  <IonCardContent>
                    <IonList
                      className={
                        invalidFields.includes("citizenshipStatus")
                          ? "invalid-field"
                          : ""
                      }
                    >
                      <IonItem>
                        <IonLabel>A citizen of the United States</IonLabel>
                        <IonCheckbox
                          checked={citizenshipStatus === "1"}
                          onIonChange={() => setCitizenshipStatus("1")}
                        />
                      </IonItem>
                      <IonItem>
                        <IonLabel>
                          A noncitizen national of the United States
                        </IonLabel>
                        <IonCheckbox
                          checked={citizenshipStatus === "2"}
                          onIonChange={() => setCitizenshipStatus("2")}
                        />
                      </IonItem>
                      <IonItem>
                        <IonLabel>A lawful permanent resident</IonLabel>
                        <IonCheckbox
                          checked={citizenshipStatus === "3"}
                          onIonChange={() => setCitizenshipStatus("3")}
                        />
                        {citizenshipStatus === "3" && (
                          <IonInput
                            className={
                              invalidFields.includes("uscisNumber")
                                ? "invalid-field"
                                : ""
                            }
                            value={uscisNumber}
                            onIonChange={(e: any) =>
                              setUscisNumber(e.detail.value)
                            }
                            placeholder="USCIS A-Number"
                          />
                        )}
                      </IonItem>
                      <IonItem>
                        <IonLabel>
                          A noncitizen authorized to work until
                        </IonLabel>
                        <IonCheckbox
                          checked={citizenshipStatus === "4"}
                          onIonChange={() => setCitizenshipStatus("4")}
                        />
                        {citizenshipStatus === "4" && (
                          <>
                            <IonInput
                              className={
                                invalidFields.includes("i94Number")
                                  ? "invalid-field"
                                  : ""
                              }
                              value={i94Number}
                              onIonChange={(e: any) =>
                                setI94Number(e.detail.value)
                              }
                              placeholder="Form I-94 Admission Number"
                            />
                            <IonInput
                              className={
                                invalidFields.includes("foreignPassportNumber")
                                  ? "invalid-field"
                                  : ""
                              }
                              value={foreignPassportNumber}
                              onIonChange={(e: any) =>
                                setForeignPassportNumber(e.detail.value)
                              }
                              placeholder="Foreign Passport Number"
                            />
                            <IonInput
                              className={
                                invalidFields.includes("countryOfIssuance")
                                  ? "invalid-field"
                                  : ""
                              }
                              value={countryOfIssuance}
                              onIonChange={(e: any) =>
                                setCountryOfIssuance(e.detail.value)
                              }
                              placeholder="Country of Issuance"
                            />
                          </>
                        )}
                      </IonItem>
                    </IonList>
                  </IonCardContent>
                </IonCard>
              </IonCard>
              {/* End Section 1 */}

              {/* New Section: Document Selection */}
              <IonCard>
                <IonCardHeader>
                  <IonCardTitle>Select Documents for Verification</IonCardTitle>
                  <IonCardSubtitle>
                    Please select either List A or List B
                  </IonCardSubtitle>
                </IonCardHeader>
                <IonCardContent>
                  <IonItem>
                    <IonLabel>List A (1 Document)</IonLabel>
                    <IonCheckbox
                      checked={listASelected}
                      onIonChange={() => {
                        setListASelected(!listASelected);
                        setListBSelected(false);
                      }}
                    />
                  </IonItem>
                  {listASelected && (
                    <IonItem>
                      <IonLabel position="stacked">Select Document</IonLabel>
                      <IonSelect
                        value={listADocument}
                        onIonChange={(e: any) =>
                          setListADocument(e.detail.value)
                        }
                      >
                        <IonSelectOption value="Passport">
                          Passport
                        </IonSelectOption>
                        <IonSelectOption value="Permanent Resident Card">
                          Permanent Resident Card
                        </IonSelectOption>
                        <IonSelectOption value="Foreign Passport with I-551 Stamp">
                          Foreign Passport with I-551 Stamp
                        </IonSelectOption>
                      </IonSelect>
                    </IonItem>
                  )}
                  <IonItem>
                    <IonLabel>List B (2 Documents Needed)</IonLabel>
                    <IonCheckbox
                      checked={listBSelected}
                      onIonChange={() => {
                        setListBSelected(!listBSelected);
                        setListASelected(false);
                      }}
                    />
                  </IonItem>
                  {listBSelected && (
                    <>
                      <IonItem>
                        <IonLabel position="stacked">
                          Select Document 1
                        </IonLabel>
                        <IonSelect
                          value={listBDocument1}
                          onIonChange={(e: any) =>
                            setListBDocument1(e.detail.value)
                          }
                        >
                          <IonSelectOption value="Driver's License">
                            Driver's License
                          </IonSelectOption>
                          <IonSelectOption value="ID Card issued by Federal, State, or Local Government">
                            ID Card issued by Federal, State, or Local
                            Government
                          </IonSelectOption>
                          <IonSelectOption value="School ID with Photograph">
                            School ID with Photograph
                          </IonSelectOption>
                        </IonSelect>
                      </IonItem>
                      <IonItem>
                        <IonLabel position="stacked">
                          Select Document 2
                        </IonLabel>
                        <IonSelect
                          value={listBDocument2}
                          onIonChange={(e: any) =>
                            setListBDocument2(e.detail.value)
                          }
                        >
                          <IonSelectOption value="Social Security Card">
                            Social Security Card
                          </IonSelectOption>
                          <IonSelectOption value="Birth Certificate">
                            Birth Certificate
                          </IonSelectOption>
                          <IonSelectOption value="Native American Tribal Document">
                            Native American Tribal Document
                          </IonSelectOption>
                        </IonSelect>
                      </IonItem>
                    </>
                  )}
                </IonCardContent>
              </IonCard>

              <IonCard>
                <IonCardHeader>
                  <IonCardTitle>Upload Photos of Documents</IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                  {listASelected && (
                    <>
                      <IonItem
                        className={
                          invalidFields.includes("driverLicensePhoto")
                            ? "invalid-field"
                            : ""
                        }
                      >
                        <IonLabel>{listADocument}</IonLabel>
                        <IonButton
                          onClick={() => takePhoto(0, "front")}
                          disabled={!listADocument} // Disable if no document type selected
                        >
                          <IonIcon icon={cameraOutline} slot="start" />
                          Take Photo
                        </IonButton>
                      </IonItem>
                      {i9DocumentPhotosData[0]?.front && (
                        <IonItem>
                          <IonButton
                            onClick={() => {
                              setPhotoToShow(i9DocumentPhotosData[0].front!);
                              setShowPhotoModal(true);
                            }}
                          >
                            VIEW PHOTO
                          </IonButton>
                          <IonButton
                            color="danger"
                            onClick={() => deletePhoto(0, "front")}
                          >
                            <IonIcon icon={trashOutline} slot="icon-only" />
                          </IonButton>
                        </IonItem>
                      )}
                    </>
                  )}
                  {listBSelected && (
                    <>
                      <IonItem
                        className={
                          invalidFields.includes("driverLicensePhoto")
                            ? "invalid-field"
                            : ""
                        }
                      >
                        <IonLabel>{listBDocument1}</IonLabel>
                        <IonButton
                          onClick={() => takePhoto(0, "front")}
                          disabled={!listBDocument1} // Disable if no document type selected
                        >
                          <IonIcon icon={cameraOutline} slot="start" />
                          Take Photo
                        </IonButton>
                      </IonItem>
                      {i9DocumentPhotosData[0]?.front && (
                        <IonItem>
                          <IonButton
                            onClick={() => {
                              setPhotoToShow(i9DocumentPhotosData[0].front!);
                              setShowPhotoModal(true);
                            }}
                          >
                            VIEW PHOTO
                          </IonButton>
                          <IonButton
                            color="danger"
                            onClick={() => deletePhoto(0, "front")}
                          >
                            <IonIcon icon={trashOutline} slot="icon-only" />
                          </IonButton>
                        </IonItem>
                      )}
                      <IonItem
                        className={
                          invalidFields.includes("ssCardPhoto")
                            ? "invalid-field"
                            : ""
                        }
                      >
                        <IonLabel>{listBDocument2}</IonLabel>
                        <IonButton
                          onClick={() => takePhoto(1, "front")}
                          disabled={!listBDocument2} // Disable if no document type selected
                        >
                          <IonIcon icon={cameraOutline} slot="start" />
                          Take Photo
                        </IonButton>
                      </IonItem>
                      {i9DocumentPhotosData[1]?.front && (
                        <IonItem>
                          <IonButton
                            onClick={() => {
                              setPhotoToShow(i9DocumentPhotosData[1].front!);
                              setShowPhotoModal(true);
                            }}
                          >
                            VIEW PHOTO
                          </IonButton>
                          <IonButton
                            color="danger"
                            onClick={() => deletePhoto(1, "front")}
                          >
                            <IonIcon icon={trashOutline} slot="icon-only" />
                          </IonButton>
                        </IonItem>
                      )}
                    </>
                  )}
                </IonCardContent>
              </IonCard>

              {/* Step 5: Sign Here */}
              <IonCard>
                <IonCardHeader>
                  <IonCardTitle>
                    <strong>Employee Signature</strong>
                    <IonCardSubtitle>
                      Under penalties of perjury, I declare that this
                      certificate, to the best of my knowledge and belief, is
                      true, correct, and complete.
                    </IonCardSubtitle>
                  </IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                  <IonButton expand="full" onClick={handleGeneratePdf}>
                    Review Document
                  </IonButton>
                  {i9PdfUrl && (
                    <>
                      <iframe
                        src={i9PdfUrl}
                        width="100%"
                        height="500px"
                        title="I-9 PDF"
                      ></iframe>
                    </>
                  )}
                  <IonButton
                    expand="full"
                    onClick={() => setShowSignatureModal(true)}
                    disabled={!i9PdfUrl}
                  >
                    Sign & Submit &nbsp;
                    <IonIcon icon={sendOutline} />
                  </IonButton>
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
        </IonGrid>

        {/* Signature Modal */}
        <IonModal
          isOpen={showSignatureModal}
          onDidDismiss={() => setShowSignatureModal(false)}
        >
          <IonHeader>
            <IonToolbar>
              <IonTitle>Sign Document</IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={() => setShowSignatureModal(false)}>
                  <IonIcon icon={close} />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <div
              style={{
                position: "relative",
                width: "100%",
                height: "200px",
                margin: "20px",
              }}
            >
              <SignatureCanvas
                ref={sigCanvas}
                penColor="black"
                canvasProps={{
                  width: 1500,
                  height: 200,
                  style: { backgroundColor: "lightgray" },
                  className: "sigCanvas",
                }}
                onEnd={() =>
                  setSignature(
                    sigCanvas.current
                      ?.getTrimmedCanvas()
                      .toDataURL("image/png") ?? null
                  )
                }
              />
            </div>
            <IonButton
              expand="full"
              onClick={clearSignature}
              disabled={!signature}
            >
              Clear
            </IonButton>
            <IonButton
              expand="full"
              onClick={handleAddSignatureToPdf}
              disabled={!signature}
            >
              Submit
            </IonButton>
          </IonContent>
        </IonModal>

        {/* Photo Modal */}
        <IonModal
          isOpen={showPhotoModal}
          onDidDismiss={() => setShowPhotoModal(false)}
        >
          <IonHeader>
            <IonToolbar>
              <IonTitle>View Photo</IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={() => setShowPhotoModal(false)}>
                  <IonIcon icon={close} />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            {photoToShow && (
              <img
                src={photoToShow}
                alt="Document Photo"
                style={{ width: "100%" }}
              />
            )}
          </IonContent>
        </IonModal>
      </IonContent>
    </IonPage>
  );
};

export default I9;
