import React from "react";
import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonBackButton,
  IonProgressBar,
} from "@ionic/react";
import { useProgress } from "../Context/ProgressContext";
import "./W4Header.css";

const W4Header: React.FC = () => {
  const { progressData } = useProgress();

  return (
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <IonBackButton defaultHref="/home" text="BACK" />
        </IonButtons>
        <IonTitle className="header-title">
          Please fill out the W-4 Form
        </IonTitle>
      </IonToolbar>
      <IonProgressBar value={progressData.W4 / 100} color="primary" />
    </IonHeader>
  );
};

export default W4Header;
