// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.highlighted-input {
  --background: #e6e3e3; /* Light grey background */
  --color: #333; /* Dark text color */
  --placeholder-color: #888; /* Grey placeholder text color */
  --highlight-background: #e0e0e0; /* Slightly darker grey for focus */
  --highlight-color: #000; /* Black text color on focus */
  background: var(--background);
  color: var(--color);
  padding: 8px;
  border-radius: 4px;
  margin: 1%;
}

.highlighted-input::placeholder {
  color: var(--placeholder-color);
}

.highlighted-input:focus {
  background: var(--highlight-background);
  color: var(--highlight-color);
}
.invalid-field {
  border: 1px solid red !important;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/I9.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB,EAAE,0BAA0B;EACjD,aAAa,EAAE,oBAAoB;EACnC,yBAAyB,EAAE,gCAAgC;EAC3D,+BAA+B,EAAE,mCAAmC;EACpE,uBAAuB,EAAE,8BAA8B;EACvD,6BAA6B;EAC7B,mBAAmB;EACnB,YAAY;EACZ,kBAAkB;EAClB,UAAU;AACZ;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,uCAAuC;EACvC,6BAA6B;AAC/B;AACA;EACE,gCAAgC;AAClC","sourcesContent":[".highlighted-input {\n  --background: #e6e3e3; /* Light grey background */\n  --color: #333; /* Dark text color */\n  --placeholder-color: #888; /* Grey placeholder text color */\n  --highlight-background: #e0e0e0; /* Slightly darker grey for focus */\n  --highlight-color: #000; /* Black text color on focus */\n  background: var(--background);\n  color: var(--color);\n  padding: 8px;\n  border-radius: 4px;\n  margin: 1%;\n}\n\n.highlighted-input::placeholder {\n  color: var(--placeholder-color);\n}\n\n.highlighted-input:focus {\n  background: var(--highlight-background);\n  color: var(--highlight-color);\n}\n.invalid-field {\n  border: 1px solid red !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
