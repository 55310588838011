import React, { useState, useRef, useEffect } from "react";
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCheckbox,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
  IonModal,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonText,
} from "@ionic/react";
import { PDFDocument, StandardFonts } from "pdf-lib";
import SignatureCanvas from "react-signature-canvas";
import { sendOutline, close } from "ionicons/icons";
import { useHistory } from "react-router-dom";
import ProgressBar from "../Components/ProgessBar";
import { useProgress } from "../Context/ProgressContext";
import "./W4.css";
import W4Header from "../Components/W4Header";

interface DollarInputProps {
  label: string;
  value: string;
  onIonChange: (e: any) => void;
  invalid: boolean;
}

const DollarInput: React.FC<DollarInputProps> = ({
  label,
  value,
  onIonChange,
  invalid,
}) => {
  const handleChange = (e: CustomEvent) => {
    const input = e.detail.value.replace(/\D/g, ""); // Remove all non-digit characters
    onIonChange({ detail: { value: input } });
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    const target = e.target as HTMLInputElement;
    if (
      (e.key === "Backspace" || e.key === "Delete") &&
      target.selectionStart === 1
    ) {
      e.preventDefault();
    }
  };

  const handleClick = (e: React.MouseEvent) => {
    const target = e.target as HTMLInputElement;
    if (target.selectionStart === 0) {
      target.setSelectionRange(1, 1);
    }
  };

  return (
    <IonItem className={invalid ? "invalid-field" : ""}>
      <IonLabel position="stacked">{label}</IonLabel>
      <IonInput
        value={`$${value}`}
        onIonChange={handleChange}
        onKeyDown={handleKeyDown}
        onClick={handleClick}
      />
    </IonItem>
  );
};

const W4: React.FC = () => {
  const history = useHistory(); // Use the useHistory hook
  const {
    progressData,
    setProgressData,
    w4PdfUrl,
    setW4PdfUrl,
    setEmployeeName,
  } = useProgress();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [zip, setZip] = useState("");
  const [ssn, setSsn] = useState("");
  const [filingStatus, setFilingStatus] = useState("");
  const [dependentCredits, setDependentCredits] = useState("");
  const [otherDependentCredits, setOtherDependentCredits] = useState("");
  const [otherIncome, setOtherIncome] = useState("");
  const [deductions, setDeductions] = useState("");
  const [extraWithholding, setExtraWithholding] = useState("");
  const [step2CheckBox, setStep2CheckBox] = useState(false);
  const [signature, setSignature] = useState<string | null>(null);
  const [invalidFields, setInvalidFields] = useState<string[]>([]);
  const [showSignatureModal, setShowSignatureModal] = useState(false);

  const sigCanvas = useRef<SignatureCanvas | null>(null);

  const calculateProgress = () => {
    let completedFields = 0;
    const totalFields = 13;

    if (firstName) completedFields++;
    if (lastName) completedFields++;
    if (address) completedFields++;
    if (city) completedFields++;
    if (state) completedFields++;
    if (zip) completedFields++;
    if (ssn) completedFields++;
    if (filingStatus) completedFields++;
    if (dependentCredits) completedFields++;
    if (otherDependentCredits) completedFields++;
    if (otherIncome) completedFields++;
    if (deductions) completedFields++;
    if (extraWithholding) completedFields++;

    return Math.round((completedFields / totalFields) * 100);
  };

  useEffect(() => {
    setProgressData((prev) => ({ ...prev, W4: calculateProgress() }));
  }, [
    firstName,
    lastName,
    address,
    city,
    state,
    zip,
    ssn,
    filingStatus,
    dependentCredits,
    otherDependentCredits,
    otherIncome,
    deductions,
    extraWithholding,
    setProgressData,
  ]);

  const companyName = "Bahnmiller Construction, Inc.";
  const companyAddress = "P.O Box 12604 Everett, WA 98206";
  const companyEIN = "34-2057505";

  const today = new Date();
  const formattedDate = `${(today.getMonth() + 1)
    .toString()
    .padStart(2, "0")}/${today
    .getDate()
    .toString()
    .padStart(2, "0")}/${today.getFullYear()}`;

  useEffect(() => {
    setEmployeeName(`${firstName} ${lastName}`);
  }, [firstName, lastName, setEmployeeName]);

  const handleSsnChange = (e: any) => {
    let input = e.detail.value;
    const numericValue = input.replace(/\D/g, "");
    let formattedSsn = "";
    for (let i = 0; i < numericValue.length; i++) {
      if (i === 3 || i === 5) {
        formattedSsn += "-";
      }
      formattedSsn += numericValue[i];
    }
    setSsn(formattedSsn);
  };

  const validateForm = () => {
    const requiredFields = [
      "firstName",
      "lastName",
      "address",
      "city",
      "state",
      "zip",
      "ssn",
      "filingStatus",
      "dependentCredits",
      "otherDependentCredits",
      "otherIncome",
      "deductions",
      "extraWithholding",
    ];

    const invalidFields = requiredFields.filter((field) => !eval(field));

    setInvalidFields(invalidFields);

    return invalidFields.length === 0;
  };

  const handleGeneratePdf = async () => {
    if (!validateForm()) {
      alert("Please fill out all required fields.");
      return;
    }

    try {
      const response = await fetch("/IRS - Form W-4.pdf");
      if (!response.ok)
        throw new Error(`HTTP error! status: ${response.status}`);
      const pdfBuffer = await response.arrayBuffer();
      const pdfDoc = await PDFDocument.load(pdfBuffer);
      const form = pdfDoc.getForm();
      const helveticaFont = await pdfDoc.embedStandardFont(
        StandardFonts.Helvetica
      );

      form
        .getTextField("topmostSubform[0].Page1[0].Step1a[0].f1_01[0]")
        .setText(firstName);
      form
        .getTextField("topmostSubform[0].Page1[0].Step1a[0].f1_02[0]")
        .setText(lastName);
      form.getTextField("topmostSubform[0].Page1[0].f1_05[0]").setText(ssn);
      form
        .getTextField("topmostSubform[0].Page1[0].Step1a[0].f1_03[0]")
        .setText(address);
      form
        .getTextField("topmostSubform[0].Page1[0].Step1a[0].f1_04[0]")
        .setText(`${city}, ${state}, ${zip}`);

      if (filingStatus === "single") {
        form.getCheckBox("topmostSubform[0].Page1[0].c1_1[0]").check();
      } else if (filingStatus === "married") {
        form.getCheckBox("topmostSubform[0].Page1[0].c1_1[1]").check();
      } else if (filingStatus === "head") {
        form.getCheckBox("topmostSubform[0].Page1[0].c1_1[2]").check();
      }

      form
        .getTextField("topmostSubform[0].Page1[0].Step3_ReadOrder[0].f1_06[0]")
        .setText(dependentCredits);
      form
        .getTextField("topmostSubform[0].Page1[0].Step3_ReadOrder[0].f1_07[0]")
        .setText(`${otherDependentCredits}`);
      form
        .getTextField("topmostSubform[0].Page1[0].f1_09[0]")
        .setText(
          `${parseFloat(dependentCredits) + parseFloat(otherDependentCredits)}`
        );
      form
        .getTextField("topmostSubform[0].Page1[0].f1_10[0]")
        .setText(`${otherIncome}`);
      form
        .getTextField("topmostSubform[0].Page1[0].f1_11[0]")
        .setText(`${deductions}`);
      form
        .getTextField("topmostSubform[0].Page1[0].f1_12[0]")
        .setText(extraWithholding);

      if (step2CheckBox) {
        form.getCheckBox("topmostSubform[0].Page1[0].c1_2[0]").check();
      }

      form
        .getTextField("topmostSubform[0].Page1[0].f1_13[0]")
        .setText(`${companyName}, ${companyAddress}`);
      form
        .getTextField("topmostSubform[0].Page1[0].f1_14[0]")
        .setText(formattedDate);
      form
        .getTextField("topmostSubform[0].Page1[0].f1_15[0]")
        .setText(companyEIN);

      const page = pdfDoc.getPages()[0];
      page.drawText(formattedDate, {
        x: 470,
        y: 165,
        size: 12,
        font: helveticaFont,
      });

      form.flatten();
      const pdfBytes = await pdfDoc.save();

      const blob = new Blob([pdfBytes], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      setW4PdfUrl(url); // Store the W-4 PDF URL in context
    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };

  const handleAddSignatureToPdf = async () => {
    try {
      if (!w4PdfUrl || !signature) return;

      const response = await fetch(w4PdfUrl);
      const pdfBuffer = await response.arrayBuffer();
      const pdfDoc = await PDFDocument.load(pdfBuffer);
      const pngImageBytes = await fetch(signature).then((res) =>
        res.arrayBuffer()
      );
      const pngImage = await pdfDoc.embedPng(pngImageBytes);

      const page = pdfDoc.getPages()[0];
      const { width, height } = page.getSize();
      page.drawImage(pngImage, {
        x: width / 2 - 200,
        y: height / 2 - 230,
        width: 60,
        height: 15,
      });

      const pdfBytes = await pdfDoc.save();
      const blob = new Blob([pdfBytes], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      setW4PdfUrl(url); // Update the W-4 PDF URL with the signed version

      setShowSignatureModal(false); // Close the modal
      // Navigate to the home page after submission
      history.push("/home");
    } catch (error) {
      console.error("Error adding signature to PDF:", error);
    }
  };

  const clearSignature = () => {
    if (sigCanvas.current) {
      sigCanvas.current.clear();
      setSignature(null);
    }
  };

  return (
    <IonPage>
      <W4Header />
      <IonContent>
        {/* <ProgressBar progress={progressData.W4} /> */}
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonCard>
                <IonCardHeader>
                  <IonCardTitle>Personal Information</IonCardTitle>
                  <IonCardSubtitle>
                    <strong>Step 1: Enter Personal Information</strong>
                  </IonCardSubtitle>
                  <IonCardSubtitle>
                    <strong>
                      Does your name match the name on your social security
                      card?
                    </strong>
                    &nbsp;If not, to ensure you get credit for your earnings,
                    contact SSA at 800-772-1213 or go to www.ssa.gov.
                  </IonCardSubtitle>
                </IonCardHeader>
                <IonCardContent>
                  <IonItem
                    className={
                      invalidFields.includes("firstName") ? "invalid-field" : ""
                    }
                  >
                    <IonLabel position="stacked">First Name</IonLabel>
                    <IonInput
                      value={firstName}
                      onIonChange={(e: any) => setFirstName(e.detail.value)}
                      placeholder="First Name"
                    />
                  </IonItem>
                  <IonItem
                    className={
                      invalidFields.includes("lastName") ? "invalid-field" : ""
                    }
                  >
                    <IonLabel position="stacked">Last Name</IonLabel>
                    <IonInput
                      value={lastName}
                      onIonChange={(e: any) => setLastName(e.detail.value)}
                      placeholder="Last Name"
                    />
                  </IonItem>
                  <IonItem
                    className={
                      invalidFields.includes("address") ? "invalid-field" : ""
                    }
                  >
                    <IonLabel position="stacked">Address</IonLabel>
                    <IonInput
                      value={address}
                      onIonChange={(e: any) => setAddress(e.detail.value)}
                      placeholder="Address"
                    />
                  </IonItem>
                  <IonItem
                    className={
                      invalidFields.includes("city") ? "invalid-field" : ""
                    }
                  >
                    <IonLabel position="stacked">City</IonLabel>
                    <IonInput
                      value={city}
                      onIonChange={(e: any) => setCity(e.detail.value)}
                      placeholder="City"
                    />
                  </IonItem>
                  <IonItem
                    className={
                      invalidFields.includes("state") ? "invalid-field" : ""
                    }
                  >
                    <IonLabel position="stacked">State</IonLabel>
                    <IonInput
                      value={state}
                      onIonChange={(e: any) => setState(e.detail.value)}
                      placeholder="State"
                    />
                  </IonItem>
                  <IonItem
                    className={
                      invalidFields.includes("zip") ? "invalid-field" : ""
                    }
                  >
                    <IonLabel position="stacked">ZIP</IonLabel>
                    <IonInput
                      value={zip}
                      onIonChange={(e: any) => setZip(e.detail.value)}
                      placeholder="ZIP"
                      maxlength={5}
                      type="tel"
                    />
                  </IonItem>
                  <IonItem
                    className={
                      invalidFields.includes("ssn") ? "invalid-field" : ""
                    }
                  >
                    <IonLabel position="stacked">
                      Social Security Number
                    </IonLabel>
                    <IonInput
                      value={ssn}
                      onIonChange={handleSsnChange}
                      placeholder="XXXXXXXXX"
                      type="tel"
                      maxlength={9}
                    />
                  </IonItem>
                </IonCardContent>
                <IonCard>
                  <IonCardHeader>
                    <IonCardTitle>
                      Please select <strong>one</strong> of the following ...
                    </IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>
                    <IonList
                      className={
                        invalidFields.includes("filingStatus")
                          ? "invalid-field"
                          : ""
                      }
                    >
                      <IonItem>
                        <IonLabel>Single or Married filing separately</IonLabel>
                        <IonCheckbox
                          checked={filingStatus === "single"}
                          onIonChange={() => setFilingStatus("single")}
                        />
                      </IonItem>
                      <IonItem>
                        <IonLabel>
                          Married filing jointly or Qualifying surviving spouse
                        </IonLabel>
                        <IonCheckbox
                          checked={filingStatus === "married"}
                          onIonChange={() => setFilingStatus("married")}
                        />
                      </IonItem>
                      <IonItem>
                        <IonLabel>Head of household</IonLabel>
                        <IonCheckbox
                          checked={filingStatus === "head"}
                          onIonChange={() => setFilingStatus("head")}
                        />
                      </IonItem>
                    </IonList>
                  </IonCardContent>
                </IonCard>
              </IonCard>
              <IonCard>
                <IonCardHeader>
                  <IonCardTitle>
                    Step 2: Multiple Jobs or Spouse Works
                  </IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                  <IonText>
                    Complete this step if you (1) hold more than one job at a
                    time, or (2) are married filing jointly and your spouse also
                    works. The correct amount of withholding depends on income
                    earned from all of these jobs. Do only one of the following.
                    <br />
                    (a) Use the estimator at www.irs.gov/W4App for most accurate
                    withholding for this step (and Steps 3-4). If you or your
                    spouse have self-employment income, use this option;{" "}
                    <strong>or</strong> <br /> (b) Use the Multiple Jobs
                    Worksheet on page 3 and enter the result in Step 4(c) below;{" "}
                    <strong>or</strong> <br /> (c) If there are only two jobs
                    total, you may check this box. Do the same on Form W-4 for
                    the other job. This option is generally more accurate than
                    (b) if pay at the lower paying job is more than half of the
                    pay at the higher paying job. <br />
                    Otherwise, (b) is more accurate . . . . . . . . . . . . . .
                    &nbsp;
                    <IonCheckbox
                      checked={step2CheckBox}
                      onIonChange={() => setStep2CheckBox(!step2CheckBox)}
                    />
                  </IonText>
                  <br />
                  <br />
                  <IonText>
                    <strong>
                      {" "}
                      Complete Steps 3–4(b) on Form W-4 for only ONE of these
                      jobs. Leave those steps blank for the other jobs.{" "}
                    </strong>{" "}
                    (Your withholding will be most accurate if you complete
                    Steps 3–4(b) on the Form W-4 for the highest paying job.)
                  </IonText>
                </IonCardContent>
              </IonCard>
              <IonCard>
                <IonCardHeader>
                  <IonCardTitle>
                    <strong>Step 3: Claim Dependent and Other Credits</strong>
                  </IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                  <IonText>
                    If your total income will be $200,000 or less ($400,000 or
                    less if married filing jointly):
                  </IonText>
                  <DollarInput
                    label="Multiply the number of qualifying children under age 17 by $2,000. . . . . (enter number below)"
                    value={dependentCredits}
                    onIonChange={(e) => setDependentCredits(e.detail.value)}
                    invalid={invalidFields.includes("dependentCredits")}
                  />
                  <DollarInput
                    label="Multiply the number of other dependents by $500 . . . . . (enter number below)"
                    value={otherDependentCredits}
                    onIonChange={(e) =>
                      setOtherDependentCredits(e.detail.value)
                    }
                    invalid={invalidFields.includes("otherDependentCredits")}
                  />
                </IonCardContent>
              </IonCard>
              <IonCard>
                <IonCardHeader>
                  <IonCardTitle>
                    <strong>Step 4 (optional): Other Adjustments</strong>
                  </IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                  <DollarInput
                    label="(a) Other income (not from jobs). If you want tax withheld for other income you expect this year that won’t have withholding, enter the amount of other income here. This may include interest, dividends, and retirement income"
                    value={otherIncome}
                    onIonChange={(e) => setOtherIncome(e.detail.value)}
                    invalid={invalidFields.includes("otherIncome")}
                  />
                  <DollarInput
                    label="(b) Deductions. If you expect to claim deductions other than the standard deduction and want to reduce your withholding, use the Deductions Worksheet on page 3 and enter the result here"
                    value={deductions}
                    onIonChange={(e) => setDeductions(e.detail.value)}
                    invalid={invalidFields.includes("deductions")}
                  />
                  <DollarInput
                    label="(c) Extra withholding. Enter any additional tax you want withheld each pay period"
                    value={extraWithholding}
                    onIonChange={(e) => setExtraWithholding(e.detail.value)}
                    invalid={invalidFields.includes("extraWithholding")}
                  />
                </IonCardContent>
              </IonCard>
              <IonCard>
                <IonCardHeader>
                  <IonCardTitle>
                    <strong>Step 5: Sign Here</strong>
                    <IonCardSubtitle>
                      Under penalties of perjury, I declare that this
                      certificate, to the best of my knowledge and belief, is
                      true, correct, and complete.
                    </IonCardSubtitle>
                  </IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                  <IonButton expand="full" onClick={handleGeneratePdf}>
                    Review Document
                  </IonButton>
                  {w4PdfUrl && (
                    <>
                      <iframe
                        src={w4PdfUrl}
                        width="100%"
                        height="500px"
                        title="W-4 PDF"
                      ></iframe>
                    </>
                  )}
                </IonCardContent>
              </IonCard>
              <IonButton
                expand="full"
                onClick={() => setShowSignatureModal(true)}
                disabled={!w4PdfUrl}
              >
                Sign & Submit &nbsp;
                <IonIcon icon={sendOutline} />
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonModal
          isOpen={showSignatureModal}
          onDidDismiss={() => setShowSignatureModal(false)}
        >
          <IonHeader>
            <IonToolbar>
              <IonTitle>Signature</IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={() => setShowSignatureModal(false)}>
                  <IonIcon icon={close} />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <IonGrid>
              <IonRow>
                <IonCol size="12" className="ion-text-center">
                  <IonText>
                    <h2>Please sign below</h2>
                  </IonText>
                  <div
                    style={{
                      position: "relative",
                      width: "100%",
                      height: "200px",
                    }}
                  >
                    <SignatureCanvas
                      ref={sigCanvas}
                      penColor="black"
                      canvasProps={{
                        width: 1500,
                        height: 200,
                        className: "sigCanvas",
                        style: { backgroundColor: "lightgray" },
                      }}
                      onEnd={() =>
                        setSignature(
                          sigCanvas.current
                            ?.getTrimmedCanvas()
                            .toDataURL("image/png") ?? null
                        )
                      }
                    />
                  </div>
                  <IonButton onClick={clearSignature}>Clear</IonButton>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol size="12" className="ion-text-center">
                  <IonButton
                    onClick={handleAddSignatureToPdf}
                    disabled={!signature}
                  >
                    Submit
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonContent>
        </IonModal>
      </IonContent>
    </IonPage>
  );
};

export default W4;
