import React, { useState } from "react";
import {
  IonPage,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonItem,
  IonLabel,
  IonIcon,
  IonInput,
  IonCard,
  IonCardHeader,
  IonCardContent,
} from "@ionic/react";
import { cameraOutline, trashOutline, addCircleOutline } from "ionicons/icons";
import { useProgress } from "../Context/ProgressContext";
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import { useHistory } from "react-router-dom";

interface Certification {
  name: string;
  front: string | null;
  back: string | null;
}

const Certifications: React.FC = () => {
  const { setProgressData, setCertificationsData } = useProgress();
  const [certifications, setCertifications] = useState<Certification[]>([]);
  const [noCertifications, setNoCertifications] = useState(false);
  const history = useHistory();

  const addCertification = () => {
    setCertifications([
      ...certifications,
      { name: "", front: null, back: null },
    ]);
    setNoCertifications(false); // Ensure "No Certifications" is disabled when adding a new certification
  };

  const updateCertification = (
    index: number,
    field: string,
    value: string | null
  ) => {
    const newCertifications = [...certifications];
    (newCertifications[index] as any)[field] = value;
    setCertifications(newCertifications);
  };

  const takePhoto = async (index: number, field: "front" | "back") => {
    try {
      const photo = await Camera.getPhoto({
        resultType: CameraResultType.DataUrl,
        source: CameraSource.Camera,
        quality: 100,
      });
      updateCertification(index, field, photo.dataUrl ?? null);
    } catch (error: any) {
      if (error.message !== "User cancelled photos app") {
        console.error("Error taking photo:", error);
      }
    }
  };

  const deletePhoto = (index: number, field: "front" | "back") => {
    updateCertification(index, field, null);
  };

  const deleteCertification = (index: number) => {
    const newCertifications = certifications.filter(
      (_, certIndex) => certIndex !== index
    );
    setCertifications(newCertifications);
  };

  const handleDone = () => {
    // Update the certifications progress
    setProgressData((prev) => ({
      ...prev,
      Certifications: 100,
    }));

    // Store the certifications data
    setCertificationsData(certifications);

    // Navigate back to the home page
    history.push("/home");
  };

  const handleViewPhoto = (url: string | null) => {
    if (url) {
      window.open(url, "_blank");
    } else {
      alert("Photo not available");
    }
  };

  return (
    <IonPage>
      <IonContent>
        <IonGrid>
          {!noCertifications && (
            <IonRow>
              <IonCol>
                <IonButton
                  expand="full"
                  color="danger"
                  onClick={() => setNoCertifications(true)}
                >
                  No Certifications
                </IonButton>
              </IonCol>
            </IonRow>
          )}

          {noCertifications && (
            <IonRow>
              <IonCol>
                <IonButton
                  expand="full"
                  color="primary"
                  onClick={() => setNoCertifications(false)}
                >
                  <IonIcon icon={addCircleOutline} /> &nbsp;Add Certifications
                </IonButton>
              </IonCol>
            </IonRow>
          )}

          {!noCertifications &&
            certifications.map((cert, index) => (
              <IonCard key={index}>
                <IonCardHeader>
                  <IonItem>
                    <IonLabel>Certification Name</IonLabel>
                    <IonInput
                      value={cert.name}
                      onIonChange={(e) =>
                        updateCertification(index, "name", e.detail.value!)
                      }
                      placeholder="Enter certification name"
                    />
                    <IonButton
                      onClick={() => deleteCertification(index)}
                      color="danger"
                    >
                      <IonIcon icon={trashOutline} />
                      &nbsp;Delete
                    </IonButton>
                  </IonItem>
                </IonCardHeader>
                <IonCardContent>
                  <IonItem>
                    <IonLabel>Certification Front</IonLabel>
                    <IonButton
                      onClick={() => takePhoto(index, "front")}
                      disabled={!cert.name}
                    >
                      <IonIcon icon={cameraOutline} slot="start" />
                      Take Photo
                    </IonButton>
                  </IonItem>
                  {cert.front && (
                    <IonItem>
                      <IonButton
                        onClick={() => deletePhoto(index, "front")}
                        color="danger"
                      >
                        <IonIcon icon={trashOutline} slot="icon-only" />
                      </IonButton>
                      <IonButton onClick={() => handleViewPhoto(cert.front)}>
                        View Photo
                      </IonButton>
                    </IonItem>
                  )}

                  <IonItem>
                    <IonLabel>Certification Back</IonLabel>
                    <IonButton
                      onClick={() => takePhoto(index, "back")}
                      disabled={!cert.name}
                    >
                      <IonIcon icon={cameraOutline} slot="start" />
                      Take Photo
                    </IonButton>
                  </IonItem>
                  {cert.back && (
                    <IonItem>
                      <IonButton
                        onClick={() => deletePhoto(index, "back")}
                        color="danger"
                      >
                        <IonIcon icon={trashOutline} slot="icon-only" />
                      </IonButton>
                      <IonButton onClick={() => handleViewPhoto(cert.back)}>
                        View Photo
                      </IonButton>
                    </IonItem>
                  )}
                </IonCardContent>
              </IonCard>
            ))}

          {!noCertifications && (
            <IonRow>
              <IonCol>
                <IonButton expand="full" onClick={addCertification}>
                  <IonIcon icon={addCircleOutline} slot="start" />
                  Add Certification
                </IonButton>
              </IonCol>
            </IonRow>
          )}

          <IonRow>
            <IonCol>
              <IonButton
                expand="full"
                onClick={handleDone}
                disabled={
                  !noCertifications &&
                  (certifications.length === 0 ||
                    certifications.some(
                      (cert) => !cert.name || !cert.front || !cert.back
                    ))
                }
              >
                Done
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Certifications;
