import axios from "axios";
import { getToken } from "./authService";

// Utility function to format date
const formatDate = (date: Date) => {
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${month}-${day}-${year}`;
};

// Upload files to the specific Expense Reports folder in the Webforms SharePoint site
export const uploadExpensesToWebforms = async (
  employeeName: string,
  files: any[]
) => {
  try {
    const accessToken = await getToken();
    const driveId =
      "b!N3NP0762NkyJRe7lSqHcpWWUfCVfAfRHmSd_VKQJlFvf8-FiYvl3QpMkgrK1SG7r"; // Correct Drive ID for Webforms
    const expenseReportsFolderId = "013BRFNLHPMOV7GTU53NGJN6TDDVWS5TW6"; // Folder ID for "Expense Reports"

    // Log the files being uploaded
    console.log("Uploading the following files:", files);

    // Upload PDF files to the "Expense Reports" folder
    for (const file of files) {
      if (file.url) {
        const response = await fetch(file.url);
        const blob = await response.blob();

        console.log(
          `Uploading ${file.name} to folder ${expenseReportsFolderId}...`
        );

        // Upload the file
        const uploadResponse = await axios.put(
          `https://graph.microsoft.com/v1.0/drives/${driveId}/items/${expenseReportsFolderId}:/${file.name}.pdf:/content`,
          blob,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "application/pdf", // Set MIME type to application/pdf
            },
          }
        );

        // Log the upload response
        console.log("Upload response:", uploadResponse.data);
      }
    }
    console.log(
      "Expenses uploaded successfully to Webforms - Expense Reports folder."
    );
  } catch (error: any) {
    console.error("Failed to upload expenses to Webforms:", error);
  }
};
