import React, { useEffect, useState } from "react";
import {
  IonPage,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonProgressBar,
  IonButton,
  IonModal,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonIcon,
  IonText,
  IonLoading,
  isPlatform,
  IonFooter,
  IonImg,
  IonToast,
} from "@ionic/react";
import { close } from "ionicons/icons";
import { useLocation } from "react-router-dom";
import Header from "../Components/Header";
import AppCard from "../Components/AppCardComponent";
import { useProgress } from "../Context/ProgressContext";
import { uploadFilesAndPhotosToFolder } from "../Utils/SharePointAPI";
import "./CustomModal.css";
import "./NewHireHome.css";
import logo from "../assets/logo.jpg";

const NewHireHome: React.FC = () => {
  const {
    progressData,
    w4PdfUrl,
    i9PdfUrl,
    directDepositPdfUrl,
    emergencyContactPdfUrl,
    employeeName,
    setProgressData,
    certificationsData,
    i9DocumentPhotosData,
    setI9DocumentPhotosData,
  } = useProgress();

  const [allFilesCompleted, setAllFilesCompleted] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showSubmitButton, setShowSubmitButton] = useState(false);
  const [showRestartModal, setShowRestartModal] = useState(false);
  const [loading, setLoading] = useState(false); // State for loading spinner
  const [documentUrl, setDocumentUrl] = useState<string | null>(null);
  const [showDocumentModal, setShowDocumentModal] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastColor, setToastColor] = useState("success");

  const location = useLocation();

  const cardData = [
    {
      title: "W-4",
      description: "Complete your W-4 form.",
      route: "/w4",
      progress: progressData.W4,
      documentUrl: w4PdfUrl,
    },
    {
      title: "I-9",
      description: "Complete your I-9 form.",
      route: "/i9",
      progress: progressData.I9,
      documentUrl: i9PdfUrl,
    },
    {
      title: "Direct Deposit",
      description: "Fill out your Direct Deposit form.",
      route: "/direct-deposit",
      progress: progressData.DirectDeposit,
      documentUrl: directDepositPdfUrl,
    },
    {
      title: "Emergency Contact",
      description: "Fill out your Emergency Contact form.",
      route: "/emergency-contact",
      progress: progressData.EmergencyContact,
      documentUrl: emergencyContactPdfUrl,
    },
    {
      title: "Certifications",
      description: "Upload your certifications.",
      route: "/certifications",
      progress: progressData.Certifications,
      documentUrl: null,
    },
  ];

  const colSize = isPlatform("mobile") ? "12" : "6";
  const totalProgress = cardData.reduce((acc, card) => acc + card.progress, 0);
  const overallProgress = totalProgress / cardData.length / 100;

  useEffect(() => {
    const allCompleted = cardData.every((card) => card.progress === 100);
    setAllFilesCompleted(allCompleted);
    if (allCompleted && location.pathname === "/home") {
      setShowModal(true);
    }
  }, [progressData, location]);

  const handleRefreshFields = () => {
    setShowRestartModal(true);
  };

  const confirmRestart = () => {
    setProgressData({
      W4: 0,
      I9: 0,
      DirectDeposit: 0,
      EmergencyContact: 0,
      Certifications: 0,
    });
    setShowModal(false);
    setShowSubmitButton(false);
    setShowRestartModal(false);
    window.location.reload();
  };

  const cancelRestart = () => {
    setShowRestartModal(false);
  };

  const handleFileUpload = async () => {
    setLoading(true); // Start the loading spinner
    try {
      if (!employeeName) {
        alert("Please complete the W-4 form to enter the employee name.");
        setLoading(false);
        return;
      }
      const folderName = employeeName.replace(/\s+/g, "-");
      const files: any = [
        { name: "W-4.pdf", url: w4PdfUrl },
        { name: "I-9.pdf", url: i9PdfUrl },
        { name: "Direct-Deposit.pdf", url: directDepositPdfUrl },
        { name: "Emergency-Contact.pdf", url: emergencyContactPdfUrl },
      ];

      const certificationPhotos = certificationsData.flatMap((cert: any) => [
        { name: `${cert.name}-Front.png`, dataUrl: cert.front },
        { name: `${cert.name}-Back.png`, dataUrl: cert.back },
      ]);

      const i9Photos = i9DocumentPhotosData
        .map((photo, index) => {
          return [
            {
              name: `${photo.documentType}-Front-${index}.png`,
              dataUrl: photo.front,
            },
            {
              name: `${photo.documentType}-Back-${index}.png`,
              dataUrl: photo.back,
            },
          ];
        })
        .flat();

      const photos = [...certificationPhotos, ...i9Photos];

      await uploadFilesAndPhotosToFolder(folderName, files, photos);

      setProgressData({
        W4: 0,
        I9: 0,
        DirectDeposit: 0,
        EmergencyContact: 0,
        Certifications: 0,
      });
      setShowModal(false);
      setShowSubmitButton(false);
      setToastMessage("Files submitted successfully!");
      setToastColor("success");
      setShowToast(true);
      window.location.reload();
    } catch (error: any) {
      console.error("Error uploading file:", error);
      setToastMessage(`Failed to upload files. Error: ${error.message}`);
      setToastColor("danger");
      setShowToast(true);
    } finally {
      setLoading(false); // Stop the loading spinner
    }
  };

  const handleViewDocument = (url: string | null) => {
    if (url) {
      setDocumentUrl(url);
      setShowDocumentModal(true);
    } else {
      alert("Document is not available.");
    }
  };

  return (
    <IonPage>
      <IonContent fullscreen className="home-content">
        <Header />
        <IonGrid>
          <IonRow className="progress-bar-container">
            <IonCol size="12" className="ion-text-center">
              <h2 className="progress-title">Overall Progress</h2>
              <IonProgressBar value={overallProgress} color="primary" />
              <div className="progress-percentage">
                {Math.round(overallProgress * 100)}%
              </div>
            </IonCol>
          </IonRow>
          <IonRow className="card-container">
            {cardData.map((card, index) => (
              <IonCol key={index} size={colSize}>
                <AppCard
                  title={card.title}
                  description={card.description}
                  route={card.route}
                  progress={card.progress}
                  documentUrl={card.documentUrl}
                  onViewDocument={handleViewDocument}
                />
              </IonCol>
            ))}
          </IonRow>
          {showSubmitButton && (
            <IonRow className="submit-button-container">
              <IonCol size="12" className="ion-text-center">
                <IonButton
                  onClick={handleFileUpload}
                  color="success"
                  expand="block"
                >
                  Submit to Bahnmiller Accounting
                </IonButton>
              </IonCol>
            </IonRow>
          )}
          <IonRow className="refresh-button-container">
            <IonCol size="12" className="ion-text-center">
              <IonButton
                onClick={handleRefreshFields}
                color="primary"
                expand="block"
              >
                RESTART
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonModal isOpen={showModal} onDidDismiss={() => setShowModal(false)}>
          <IonHeader>
            <IonToolbar>
              <IonTitle>Submission Ready</IonTitle>
              <IonButtons slot="end">
                <IonButton
                  onClick={() => {
                    setShowModal(false);
                    setShowSubmitButton(true);
                  }}
                >
                  <IonIcon icon={close} />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <IonGrid>
              <IonRow>
                <IonCol size="12" className="ion-text-center">
                  <IonText>
                    <h2>All forms have been completed!</h2>
                    <p>Please submit the files to Bahnmiller Accounting.</p>
                  </IonText>
                  <IonButton
                    onClick={handleFileUpload}
                    color="success"
                    expand="block"
                  >
                    Submit to Bahnmiller Accounting
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonContent>
        </IonModal>

        <IonModal
          isOpen={showRestartModal}
          onDidDismiss={() => setShowRestartModal(false)}
        >
          <IonHeader>
            <IonToolbar>
              <IonTitle>Confirm Restart</IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={cancelRestart}>
                  <IonIcon icon={close} />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <IonGrid>
              <IonRow>
                <IonCol size="12" className="ion-text-center">
                  <IonText>
                    <h2>Are you sure you want to restart?</h2>
                    <p>All progress will be lost.</p>
                  </IonText>
                  <IonButton
                    onClick={confirmRestart}
                    color="danger"
                    expand="block"
                  >
                    Restart
                  </IonButton>
                  <IonButton
                    onClick={cancelRestart}
                    color="primary"
                    expand="block"
                  >
                    Cancel
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonContent>
        </IonModal>

        <IonModal
          isOpen={showDocumentModal}
          onDidDismiss={() => setShowDocumentModal(false)}
          className="custom-modal"
        >
          <IonHeader>
            <IonToolbar>
              <IonTitle>View Document</IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={() => setShowDocumentModal(false)}>
                  <IonIcon icon={close} />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className="custom-modal-content">
            {documentUrl && (
              <iframe
                src={documentUrl}
                width="100%"
                height="100%"
                title="Document Viewer"
              ></iframe>
            )}
          </IonContent>
        </IonModal>

        {/* Toast for success or error messages */}
        <IonToast
          isOpen={showToast}
          onDidDismiss={() => setShowToast(false)}
          message={toastMessage}
          duration={5000} // Set the duration to 5 seconds
          color={toastColor}
          position="top" // Set the position to top
        />

        {/* Loading Spinner */}
        <IonLoading isOpen={loading} message={"Uploading documents..."} />
      </IonContent>
    </IonPage>
  );
};

export default NewHireHome;
