// HELPER FUNCTIONS
export const formatDate = (date: Date) =>
  `${String(date.getMonth() + 1).padStart(2, "0")}/${String(
    date.getDate()
  ).padStart(2, "0")}/${date.getFullYear()}`;

export const isToday = (date: Date): boolean => {
  const today = new Date();
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
};

export const isWeekend = (dayIndex: number): boolean => {
  return dayIndex === 0 || dayIndex === 6;
};

export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const getCurrentMonthName = (date: Date = new Date()): string => {
  return monthNames[date.getMonth()];
};

// utils/dateUtils.ts

export const getMonthlyCount = (
  items: any[],
  targetDate: Date = new Date()
): number => {
  let monthCount = 0;

  items.forEach((item: any) => {
    const date = parseDate(item.name);
    if (date) {
      const parsedDate = new Date(date);
      if (
        parsedDate.getMonth() === targetDate.getMonth() &&
        parsedDate.getFullYear() === targetDate.getFullYear()
      ) {
        monthCount++;
      }
    }
  });

  return monthCount;
};

export function parseDate(dateString: string): string {
  if (/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString)) {
    return dateString;
  }
  return "";
}

// Helper function to get this Monday
export const getThisMonday = (): Date => {
  const now = new Date();
  const day = now.getDay(); // Sunday = 0, Monday = 1, ..., Saturday = 6
  const distanceToThisMonday = day === 0 ? 6 : day - 1; // Calculate distance to this Monday
  const thisMonday = new Date(now);
  thisMonday.setDate(now.getDate() - distanceToThisMonday); // Set the date to this Monday
  thisMonday.setHours(0, 0, 0, 0); // Set time to midnight of this Monday
  return thisMonday;
};

// Helper function to get the previous Monday
export const getPreviousMonday = (): Date => {
  const thisMonday = getThisMonday();
  const previousMonday = new Date(thisMonday);
  previousMonday.setDate(thisMonday.getDate() - 7); // Move one week back
  return previousMonday;
};

// Check if today is Monday and we are before midnight
export const isTodayMondayBeforeMidnight = (): boolean => {
  const now = new Date();
  const thisMonday = getThisMonday();
  const nextMonday = new Date(thisMonday);
  nextMonday.setDate(thisMonday.getDate() + 7); // Get next Monday
  nextMonday.setHours(0, 0, 0, 0); // Midnight next Monday
  return now >= thisMonday && now < nextMonday; // Check if we are still in Monday
};

// Disable dates before the previous Monday if past Monday midnight, and allow only the last 7 days on Monday
export const disablePastDates = (selectedDate: string | null) => {
  if (!selectedDate) return false;

  const today = new Date();
  const selected = new Date(selectedDate);

  // Get the current day of the week (0 for Sunday, 1 for Monday, ..., 6 for Saturday)
  const currentDay = today.getDay();

  // Calculate the last Monday (if today is Monday, this is today)
  const lastMonday = new Date(today);
  lastMonday.setDate(today.getDate() - (currentDay === 0 ? 6 : currentDay - 1)); // Move back to Monday

  // Calculate Monday midnight (the start of the week, next Monday 00:00:00)
  const mondayMidnight = new Date(lastMonday);
  mondayMidnight.setHours(24, 0, 0, 0); // Monday at midnight (next day)

  // If today is past Monday midnight (meaning today is any day after Monday)
  if (today > mondayMidnight) {
    // Disable any date before last Monday
    if (selected < lastMonday) {
      return true; // Disable the date if it's before last Monday
    }
  }

  // If today is Monday, only allow the last 7 days to be selected
  if (currentDay === 1) {
    const oneWeekAgo = new Date(today);
    oneWeekAgo.setDate(today.getDate() - 7); // Get last Monday
    if (selected < oneWeekAgo) {
      return true; // Disable the date if it's more than 7 days ago on a Monday
    }
  }

  return false; // Enable the date otherwise
};
