import React from "react";
import {
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonCardHeader,
  IonCardSubtitle,
  IonIcon,
  IonRouterLink,
  IonButton,
} from "@ionic/react";
import { AwesomeButton } from "react-awesome-button";
import { arrowForward } from "ionicons/icons";
import "./AppCardComponent.css";

interface AppCardProps {
  title: string;
  description: string;
  route: string;
  progress: any;
  documentUrl: string | null;
  onViewDocument: (url: string | null) => void;
}

const AppCard: React.FC<AppCardProps> = ({
  title,
  description,
  route,
  progress,
  documentUrl,
  onViewDocument,
}) => {
  const radius = 15.9155; // Radius of the circle
  const circumference = 2 * Math.PI * radius; // Circumference of the circle

  // Calculate the stroke-dashoffset to make the circle start empty and fill as progress increases
  const strokeDashoffset = (1 - progress / 100) * circumference;

  return (
    <IonCard className="app-card">
      <IonCardHeader className="app-card-header">
        <div className="header-content">
          <IonCardTitle className="app-card-title">{title}</IonCardTitle>
          <div className="progress-indicator">
            <svg viewBox="0 0 36 36" className="circular-chart blue">
              <path
                className="circle-bg"
                d="M18 2.0845
                a 15.9155 15.9155 0 0 1 0 31.831
                a 15.9155 15.9155 0 0 1 0 -31.831"
              />
              <path
                className="circle"
                strokeDasharray={circumference}
                strokeDashoffset={strokeDashoffset}
                d="M18 2.0845
                a 15.9155 15.9155 0 0 1 0 31.831
                a 15.9155 15.9155 0 0 1 0 -31.831"
              />
              <text x="18" y="20.35" className="percentage">
                {progress}%
              </text>
            </svg>
          </div>
        </div>
      </IonCardHeader>
      <IonCardContent className="app-card-content">
        <IonCardSubtitle className="app-card-subtitle">
          {description}
        </IonCardSubtitle>
        <div className="card-button-wrapper">
          <IonRouterLink routerLink={route} className="card-button-container">
            <AwesomeButton type="primary">
              <IonIcon icon={arrowForward} />
            </AwesomeButton>
          </IonRouterLink>
          {documentUrl && (
            <IonButton
              color="tertiary"
              onClick={() => onViewDocument(documentUrl)}
            >
              View Document
            </IonButton>
          )}
        </div>
      </IonCardContent>
    </IonCard>
  );
};

export default AppCard;
