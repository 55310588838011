// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Highlighted input styles */
.highlighted-input {
  font-size: 16px;
  box-sizing: border-box;
  --background: #e6e3e3; /* Light grey background */
  margin: 1%;
}

/* Disabled card styles */
.disabled-card {
  color: #aaa !important; /* Grey out text */
  pointer-events: none; /* Disable interactions */
  opacity: 0.6; /* Visual indication of being disabled */
}

.disabled-card ion-label {
  color: #aaa !important; /* Ensure IonLabel is greyed out */
}

.disabled-card ion-input {
  color: #aaa !important;
}

/* Ensure text wraps properly */
.ion-card-title,
.ion-card-subtitle,
.ion-label,
.ion-input {
  word-wrap: break-word; /* Ensure text wraps within the element */
  white-space: normal; /* Ensure normal whitespace handling */
}

/* Ensure container is responsive */
.ion-card-content,
.ion-item,
.ion-label,
.ion-input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%; /* Ensure full width */
}

/* Ensure buttons and inputs are responsive */
.ion-button,
.ion-input {
  width: 100%; /* Ensure buttons and inputs take full width */
}
`, "",{"version":3,"sources":["webpack://./src/Pages/W4.css"],"names":[],"mappings":"AAAA,6BAA6B;AAC7B;EACE,eAAe;EACf,sBAAsB;EACtB,qBAAqB,EAAE,0BAA0B;EACjD,UAAU;AACZ;;AAEA,yBAAyB;AACzB;EACE,sBAAsB,EAAE,kBAAkB;EAC1C,oBAAoB,EAAE,yBAAyB;EAC/C,YAAY,EAAE,wCAAwC;AACxD;;AAEA;EACE,sBAAsB,EAAE,kCAAkC;AAC5D;;AAEA;EACE,sBAAsB;AACxB;;AAEA,+BAA+B;AAC/B;;;;EAIE,qBAAqB,EAAE,yCAAyC;EAChE,mBAAmB,EAAE,sCAAsC;AAC7D;;AAEA,mCAAmC;AACnC;;;;EAIE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,WAAW,EAAE,sBAAsB;AACrC;;AAEA,6CAA6C;AAC7C;;EAEE,WAAW,EAAE,8CAA8C;AAC7D","sourcesContent":["/* Highlighted input styles */\n.highlighted-input {\n  font-size: 16px;\n  box-sizing: border-box;\n  --background: #e6e3e3; /* Light grey background */\n  margin: 1%;\n}\n\n/* Disabled card styles */\n.disabled-card {\n  color: #aaa !important; /* Grey out text */\n  pointer-events: none; /* Disable interactions */\n  opacity: 0.6; /* Visual indication of being disabled */\n}\n\n.disabled-card ion-label {\n  color: #aaa !important; /* Ensure IonLabel is greyed out */\n}\n\n.disabled-card ion-input {\n  color: #aaa !important;\n}\n\n/* Ensure text wraps properly */\n.ion-card-title,\n.ion-card-subtitle,\n.ion-label,\n.ion-input {\n  word-wrap: break-word; /* Ensure text wraps within the element */\n  white-space: normal; /* Ensure normal whitespace handling */\n}\n\n/* Ensure container is responsive */\n.ion-card-content,\n.ion-item,\n.ion-label,\n.ion-input {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  width: 100%; /* Ensure full width */\n}\n\n/* Ensure buttons and inputs are responsive */\n.ion-button,\n.ion-input {\n  width: 100%; /* Ensure buttons and inputs take full width */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
