import React from "react";
import { Redirect, Route } from "react-router-dom";
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { homeOutline, documentOutline, cashOutline } from "ionicons/icons";
import NewHireHome from "./Pages/NewHireHome";
import I9 from "./Pages/I9";
import W4 from "./Pages/W4";
import DirectDeposit from "./Pages/DirectDeposit";
import EmergencyContact from "./Pages/EmergencyContact";
import { ProgressProvider } from "./Context/ProgressContext";

import "@ionic/react/css/core.css";
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";
import "./theme/variables.css";
import "react-awesome-button/dist/styles.css";
import Certifications from "./Pages/Certifications";
import HomeSelection from "./Pages/HomeSelection";
import Expenses from "./Pages/ExpenseTracker/Expenses";

setupIonicReact();

const App: React.FC = () => {
  return (
    <ProgressProvider>
      <IonApp>
        <IonReactRouter>
          <IonTabs>
            <IonRouterOutlet>
              {/* Home Selection Route */}
              <Route exact path="/homeSelection">
                <HomeSelection />
              </Route>

              {/* Redirect root to /homeSelection */}
              <Route exact path="/">
                <Redirect to="/homeSelection" />
              </Route>

              {/* New Hire App Routes */}
              <Route path="/newhire">
                <NewHireHome />
              </Route>
              <Route path="/w4">
                <W4 />
              </Route>
              <Route path="/i9">
                <I9 />
              </Route>
              <Route path="/direct-deposit">
                <DirectDeposit />
              </Route>
              <Route path="/emergency-contact">
                <EmergencyContact />
              </Route>
              <Route path="/certifications" exact>
                <Certifications />
              </Route>

              {/* Expenses App Route */}
              <Route path="/expenses">
                <Expenses />
              </Route>
            </IonRouterOutlet>

            {/* Tab bar with Home, New Hire, and Expenses */}
            <IonTabBar slot="bottom">
              {/* Home tab for homeSelection */}
              <IonTabButton tab="homeSelection" href="/homeSelection">
                <IonIcon icon={homeOutline} />
                <IonLabel>Home</IonLabel>
              </IonTabButton>

              {/* New Hire tab */}
              <IonTabButton tab="newHire" href="/newhire">
                <IonIcon icon={documentOutline} />
                <IonLabel>New Hire</IonLabel>
              </IonTabButton>

              {/* Expenses tab */}
              <IonTabButton tab="expenses" href="/expenses">
                <IonIcon icon={cashOutline} />
                <IonLabel>Expenses</IonLabel>
              </IonTabButton>
            </IonTabBar>
          </IonTabs>
        </IonReactRouter>
      </IonApp>
    </ProgressProvider>
  );
};

export default App;
