import React from "react";
import { IonHeader, IonToolbar, IonTitle } from "@ionic/react";

const Header = () => {
  const getGreetingMessage = () => {
    const currentHour = new Date().getHours();
    let greeting;

    if (currentHour < 12) {
      greeting = "Good Morning";
    } else if (currentHour < 18) {
      greeting = "Good Afternoon";
    } else {
      greeting = "Good Evening";
    }

    // Check if screen width is less than or equal to 768px (mobile devices)
    if (window.innerWidth <= 768) {
      return greeting;
    }

    // Full message for non-mobile devices
    return "Welcome! Please Fill out the following forms.";
  };

  return (
    <IonHeader style={{ "--background": "#001f3f" }}>
      <IonToolbar style={{ "--background": "#001f3f", "--color": "white" }}>
        <IonTitle>{getGreetingMessage()}</IonTitle>
      </IonToolbar>
    </IonHeader>
  );
};

export default Header;
